import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';

export interface Station {
  name: string,
  code: string,
  countryCode: string,
  shortName: string,
  timeZoneOffSet: number
}

export interface Country {
  name: string,
  code: string,
  code3C: string
}
export interface StationLoginViewModel {
  name: string,
  code: string,
  countryCode: string,
  shortName: string,
  timeZoneOffSet: number,
  country?: Country
}

export interface CheckInTimetable {
  closeTime: number,
  openTime: number,
  singaporeOpenTime: number
}

interface ResourceResponse {
  stations: Station[],
  countries: Country[],
  timetable: CheckInTimetable
}

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private readonly resource$: Observable<ResourceResponse>;

  constructor(
    private httpClient: HttpClient
  ) {
    this.resource$ = this.httpClient.get<ResourceResponse>('/resource')
    .pipe(
      map((response) => response),
      shareReplay(1),
    );
  }

  getStations$(): Observable<Station[]> {
    return this.resource$.pipe(map(resource => resource.stations));
  }

  getCountries$(): Observable<Country[]> {
    return this.resource$.pipe(map(resource => resource.countries));
  }

  getCheckInTimetable$(): Observable<CheckInTimetable> {
    return this.resource$.pipe(map(resource => resource.timetable));
  }
}
