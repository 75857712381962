import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueueEmailDialogService {
  public displayDialog = new BehaviorSubject<boolean>(false);
  public redirectToLogin$: ReplaySubject<void>;
  constructor() {
    this.redirectToLogin$ = new ReplaySubject<void>();
   }

  show() {
    this.displayDialog.next(true);
  }

  hide() {
    this.displayDialog.next(false);
  }
}
