import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';

export interface Booking {
  recordLocator: string;
  passengers: Passenger[];
  journey: Journey;
  contactEmailAddress: string;
}

export interface Passenger {
  key: string;
  title: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  gender: number;
  nationality: string;
  passengerTypeCode: string;
  countryOfBirth: string;
  travelDocumentKey: string;
  passportNumber: string;
  passportExpiryDate?: Date;
  declaration1: boolean;
  declaration2: boolean;
  declaration3: boolean;
  hasOkGo: boolean;
  hasRestrictedSsr: boolean;
  isBufferSeat: boolean;
  queue: string;
  queueDisplay: string;
  systemQueue: string;
  isCheckedIn: boolean;
  staffInQueue: number;
  canCheckIn: boolean;
}

export interface Journey {
  index: number;
  key: string;
  departureStation: string;
  arrivalStation: string;
  departureDate: Date;
  arrivalDate: Date;
  segments: Segment[];
}

export interface Segment{
  key: string;
  departureStation: string;
  arrivalStation: string;
  departureDate: Date;
  arrivalDate: Date;
  identifier: string;
  carrierCode: string;
  legs: Leg[];
  hasNextSegment: Boolean;
  transitTime: string;
  hasPlaneChange: Boolean;
}

export interface Leg{
  destination: string;
  origin: string;
  arrival: Date;
  departure: Date;
  arrivalTerminal: string;
  arrivalTimeUtc: Date;
  departureTerminal: string;
  departureTimeUtc: Date;
  travelDuration: number;
  hasNextLeg: Boolean;
  transitTime: string;
  hasPlaneChange: Boolean;
}

export interface Session {
  token: string;
  expiresIn: number;
}

export interface ApplicationError {
  type: string;
  flightNumber: string;
  flightTime: string;
}

export interface Queue {
    passengerKey: string;
    queueNumber: string;
    systemQueueNumber: string;
    hasRestrictedSsr : boolean;
    isBufferSeat : boolean;
    staffInQueue: number;
    canCheckIn: boolean;
}

export interface  FlightQueue {
  flightDesignator: string;
  firstName: string;
  lastName: string;
  referenceCode: string;
  queueCode: string;
  queueNumber: string;
  createdDateTime: Date;
  flightTimes: string;
  canCheckIn: boolean;
  hasRestrictedSSr: boolean;
  isBufferSeat: boolean;
  cabinClass: string;
  equipmentType: string;
}

export interface Flight {
  flightNumber: string;
  departureStation: string;
  arrivalStation: string;
  departureDate: Date;
  openForCheckIn: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {
  private _booking$: BehaviorSubject<Booking | null>;
  session$: BehaviorSubject<Session>;
  applicationError$: BehaviorSubject<ApplicationError>;

  constructor() {
    this._booking$ = new BehaviorSubject<Booking | null>(null);
    this.session$ = new BehaviorSubject({} as Session);
    this.applicationError$ = new BehaviorSubject({} as ApplicationError);
  }

  get booking$(): Observable<Booking> {
    return this._booking$.asObservable().pipe(
      filter((booking) => !!booking),
      map(booking => booking as Booking)
    );
  }

  updateSession(session: Session) {
    this.session$.next(session);
  }

  clearSession() {
    this.session$.next({} as Session);
  }

  updateBooking(booking: Booking) {
    this._booking$.next(booking);
  }

  clearBooking() {
    this._booking$.next({} as Booking);
  }

  updateApplicationError(failure: ApplicationError) {
    this.applicationError$.next(failure);
  }

  clearApplicationError() {
    this.applicationError$.next({} as ApplicationError);
  }

  clearAll() {
    this.session$.next({} as Session);
    this._booking$.next({} as Booking);
    this.applicationError$.next({} as ApplicationError);
  }
}
