<h1 class="tw-text-4 lg:tw-text-4xl tw-mb-5">Queue Management Portal (Staff travel)</h1>

<div class="scoot-card tw-w-[345px] lg:tw-w-[560px]">
  <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
    <div *ngIf="errorMessage" class="tw-text-0xl tw-m-0 tw-mb-0.75 tw-text-red-600" [innerHTML]="errorMessage"></div>
    <div class="tw-mb-5 lg:tw-mb-[0.375rem]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75 tw-flex" for="bookingReference">
        Username
        <span class="tw-ml-2 tooltip-username">
          <img src="/assets/images/question-mark.svg"/>
          <span class="tooltiptext tw-bg-scoot-yellow-20 tw-text-1 tw-w-56 tw-p-2 tw-rounded-xl tw--mt-20 tw--ml-4">Enter your Navitaire username and password</span>
        </span>
      </label>
      <input
        class="tw-text-3 lg:tw-text-1xl tw-border-b tw-border-b-scoot-black-100 tw-w-full"
        type="text"
        id="username"
        placeholder="Enter Username"
        formControlName="username"
        [class.is-invalid]="isInvalid('username')"
      />
    </div>
    <div class="tw-mb-5 lg:tw-mb-[2.125rem]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75" for="departing">Password</label>
      <div>
        <input
          class="tw-text-3 lg:tw-text-1xl tw-border-b tw-border-b-scoot-black-100 tw-w-full"
          type="password"
          id="password"
          placeholder="Enter Password"
          formControlName="password"
          [class.is-invalid]="isInvalid('password')"
        />
      </div>
    </div>
    <div class="tw-flex tw-justify-end">
      <button class="scoot-button scoot-button--primary" type="submit">
        Login
        <svg
          width="24"
          height="16"
          viewBox="0 0 24 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="tw-leading-zero tw-fill-current tw-text-white tw-ml-2.5">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16 0L14.59 1.41L20.17 7H0V9H20.17L14.59 14.59L16 16L24 8L16 0Z"/>
        </svg>
      </button>
    </div>
  </form>
</div>
