import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {catchError, Observable, throwError} from 'rxjs';
import {AccountService} from "../services/account.service";

@Injectable()
export class UnhandledErrorInterceptor implements HttpInterceptor {

  CUSTOM_TOKEN_EXPIRED = 'Token expired.';

  constructor(private router: Router, private accountService: AccountService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('ApiErrorInterceptor - ', error);
        if (error.status === 500 
            || error.status == 429
            || error.status == 0) {
          console.log('router url', this.router.url);
          if (this.router.url.includes('admin')) {
            this.router.navigate(['error-admin']);
          } else {
            this.router.navigate(['error']);
          }
        }
        if (error.status === HttpStatusCode.Unauthorized) {
          // if (error?.error === this.CUSTOM_TOKEN_EXPIRED) {
          //   this.accountService.logOut();
          // }
          window.location.reload();
        }
        return throwError(() => error);
      })
    );
  }
}
