<div class="lg:tw-mx-80">
  <h2 class="tw-text-2xl tw-mb-5 lg:tw-text-4xl lg:tw-mb-7.5">Depart: {{this.getStationName(passengerItinerary.journey.segments[0].departureStation)}} to {{this.getStationName(passengerItinerary.journey.segments[0].arrivalStation)}}</h2>
  <ng-template #itineraryCardHeaderTemplate>
    <div class="tw-text-1xl tw-font-bold tw-float-left tw-flex">
      <img class="tw-h-[24px] tw-w-[24px] tw-mr-2" src="/assets/images/scoot-logo.svg"/>
      {{passengerItinerary.journey.segments[0].carrierCode}}{{passengerItinerary.journey.segments[0].identifier}}
    </div>
    <div class="tw-text-1xl tw-float-right">{{passengerItinerary.journey.departureStation}} - {{passengerItinerary.journey.arrivalStation}}</div>
  </ng-template>
  <ng-template #itineraryCardBodyTemplate>
    <ng-container *ngFor="let segment of passengerItinerary.journey.segments">
      <ng-container *ngFor="let leg of segment.legs">
        <div class="tw-px-3.5 lg:tw-px-7.5">
          <div class="flight-leg tw-py-5">
            <div class="flight-card-detail">
              <div class="timeline">
                <div class="icon {{this.getDepartureIcon(leg, segment)}}"></div>
                <div class="line"></div>
                <div class="icon {{this.getArrivalIcon(leg, segment)}}"></div>
              </div>
              <div class="departure-city">{{this.getStationName(leg.origin)}}</div>
              <div class="departure-terminal">{{this.getAirportName(leg.origin)}}, Terminal {{leg.departureTerminal}}</div>
              <div class="departure-time">{{ leg.departure | date :'HH:mm'}}</div>
              <div class="departure-date">{{ leg.departure | date :'EEE, MMM dd'}}</div>
    
              <div class="arrival-city">{{this.getStationName(leg.destination)}}</div>
              <div class="arrival-terminal">{{this.getAirportName(leg.destination)}}, Terminal {{leg.arrivalTerminal}}</div>
              <div class="arrival-time">{{ leg.arrival | date :'HH:mm'}}</div>
              <div class="arrival-date">{{ leg.arrival | date :'EEE, MMM dd'}}</div>
            </div>
            <div class="flight-duration">Flight Duration {{this.GetFlightDuration(leg.travelDuration)}}</div>
          </div>
        </div> 
        <div *ngIf="leg.hasNextLeg" class="tw-mx-3.5 lg:tw-mx-7.5 tw-border-t-2 tw-border-b-2 tw-flex tw-pt-7 tw-pb-7">
          <div class="tw-mr-2"><img src="/assets/images/stops-seat.svg"/></div>
          <div>
            <div>1 stop, no plane change</div>
            <div>Transit time {{leg.transitTime}}m</div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="segment.hasNextSegment" class="tw-mx-3.5 lg:tw-mx-7.5 tw-border-t-2 tw-border-b-2 tw-flex tw-pt-7 tw-pb-7">
        <div class="tw-mr-2"><img src="/assets/images/stops-seat.svg"/></div>
        <div>
          <div>1 stop, no plane change</div>
          <div>Transit time {{segment.transitTime}}m</div>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #itineraryCardFooterTemplate>
    <div class="tw-flex tw-justify-between tw-pb-2.5">
      <div class="tw-text-1xl tw-font-bold tw-ml-3.75">
        Passengers
      </div>
      <div class="tw-text-1xl tw-font-bold tw-ml-3.75 tw-pr-7">
        Status
      </div>
    </div>
    <ng-container *ngFor="let passenger of passengerItinerary.passengers">
      <div *ngIf="!isLoading" class="tw-flex tw-justify-between tw-pb-2.5">
        <div class="tw-text-1xl tw-ml-3.75">
          <div class="tw-flex">
            <div *ngIf="displayWarningIcon(passenger)" class="tw-mr-3"><img src="/assets/images/red-alert.svg"/></div>
            {{passenger.firstName}} {{passenger.lastName}}
          </div>
          <div *ngIf="passenger.hasRestrictedSsr" class="tw-mt-2 tw-text-3.25 tw-max-w-xl tw-text-scoot-red-error">
            <p>You are not eligible for online check-in.</p>
            <p class="tw-mt-1">Due to special handling requirements, please proceed to the Flight Management Counter for your check-in.</p>
          </div>
          <div *ngIf="passenger.isBufferSeat && !passenger.hasRestrictedSsr && !passenger.canCheckIn" class="tw-mt-2 tw-text-3.25 tw-max-w-xl tw-text-scoot-red-error">
            <!-- <p>You are on the queue now as the flight is full with {{passenger.staffInQueue}} passengers on standby.</p> -->
            <p>To check the flight status and complete check-in, please proceed to the Kiosk or Flight Management Counter 65 mins before ETD.</p>
            <p class="tw-mt-1">*Any acceptance is subjected to seat availability.</p>
          </div>
          <div *ngIf="isQueueWithNoBuffer(passenger)" class="tw-mt-2 tw-text-3.25 tw-max-w-xl tw-text-scoot-red-error">
            <p>You are on the queue now as the flight is full with {{passenger.staffInQueue}} passengers on standby.</p>
            <p class="tw-mt-1">To check on your flight status, please proceed to Flight Management Counter 55 mins before ETD.</p>
            <p class="tw-mt-1">*Any acceptance is subjected to seat availability.</p>
            <p class="tw-mt-1">*Your Queue number is only valid for this flight.</p>
            <!-- <p class="tw-mt-1">*In the event, that you are moved to another flight, your queue number will be reset for your new flight.</p> -->
          </div>
        </div>
        <div class="tw-text-1xl tw-ml-3.75 tw-pr-7">
          {{passenger.queueDisplay}}
        </div>
      </div>
    </ng-container>
  </ng-template>
  <app-scoot-card
    [scootCardHeaderTemplate]="itineraryCardHeaderTemplate"
    [scootCardBodyTemplate]="itineraryCardBodyTemplate"
    [scootCardFooterTemplate]="itineraryCardFooterTemplate"
  ></app-scoot-card>
  <div *ngIf="!isCheckedIn()">
    <app-alternative-email [alternativeEmailControl]="alternativeEmail"></app-alternative-email>
    <app-queue-notice-dialog></app-queue-notice-dialog>
    <app-queue-email-dialog></app-queue-email-dialog>
  </div>
</div>
