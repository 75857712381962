import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface SeatAvailabilityItem {
  aircraftType: string,
  class: string,
  available: number
}

interface SeatAvailabilityClassItem {
  name: string,
  code: string
}

@Component({
  selector: 'app-seat-availability-table',
  templateUrl: './seat-availability-table.component.html',
  styleUrls: ['./seat-availability-table.component.scss']
})
export class SeatAvailabilityTableComponent {
  @Input() seatAvailabilityItems: SeatAvailabilityItem[];
  @Output() onChange = new EventEmitter<null>();
  classItems: SeatAvailabilityClassItem[] = [
    { name: 'Economy', code: 'Economy' },
    { name: 'Scoot Plus', code: 'ScootPlus' },
  ];
  constructor() { 
  }
  
  AddItem() {
    this.seatAvailabilityItems.push({} as SeatAvailabilityItem);
    this.onChange.emit();
  }

  RemoveItem(index: number) {
    this.seatAvailabilityItems.splice(index, 1);
    this.onChange.emit();
  }
}
