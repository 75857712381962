import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, map, Observable, of } from 'rxjs';
import { SKIP_AUTHORIZATION } from '../api.interceptor';
import { AccountInformation, AccountService } from './account.service';
import { PageLoaderService } from './page-loader.service';
import { TokenService } from './token.service';

interface adminLoginResponse {
  token: string,
  accountName: string;
  primaryLocation: string,
  roleCodes: string[]
}
@Injectable({
  providedIn: 'root'
})
export class AdminLoginService {
  loginForm: FormGroup;
  errorMessage: string | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private tokenService: TokenService,
    private accountService: AccountService,
    private httpClient: HttpClient
  ) { 
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  login$(username: string, password: string): Observable<boolean> {    
    const context = new HttpContext().set(SKIP_AUTHORIZATION, true);
    return this.httpClient
      .post<adminLoginResponse>(
        '/token', 
        { username, password }, 
        { context: context })
      .pipe(
        map((response) => { 
          this.tokenService.setToken(response.token);
          this.accountService.setAccountInfo({ 
            accountName: response.accountName,
            primaryLocation: response.primaryLocation,
            roleCodes: response.roleCodes
          } as AccountInformation);
          return true;
        })
      );
  }
}
