import { Component, OnInit } from '@angular/core';
import { AbstractFormGroupDirective } from '@angular/forms';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedInUser: string;
  isLoggedIn: boolean = false;

  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.accountService.getAccountInfo$().subscribe(
      account => {
        if (account === null || account.accountName === undefined){
          this.isLoggedIn = false;  
          return;
        }
        this.loggedInUser = account.accountName;
        this.isLoggedIn = true;
      }
    );
  }

  logout() {
    this.isLoggedIn = false;
    this.accountService.logOut();
  }
}
