import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { TokenService } from './token.service';

export interface AccountInformation {
  accountName: string;
  primaryLocation: string;
  roleCodes: string[];
}

const ACCOUNT_INFO_KEY = "accountInfo";

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private loggedInAccount$ = new ReplaySubject<AccountInformation>(1);

  constructor(
    private tokenService: TokenService
  ) {
    this.loggedInAccount$.next({} as AccountInformation);
  }

  getAccountInfo$(): Observable<AccountInformation> {
    const localAccountInfo = sessionStorage.getItem(ACCOUNT_INFO_KEY);
    if (localAccountInfo){
      return of(JSON.parse(localAccountInfo) as AccountInformation);
    }
    return this.loggedInAccount$;
  }

  setAccountInfo(accountInfo: AccountInformation) {
    this.loggedInAccount$.next(accountInfo);
    sessionStorage.setItem(ACCOUNT_INFO_KEY, JSON.stringify(accountInfo));
  }

  logOut() {
    this.loggedInAccount$.next({} as AccountInformation);
    this.tokenService.setToken('');
    sessionStorage.removeItem(ACCOUNT_INFO_KEY);
  }
}
