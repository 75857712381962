import { TranslationWidth } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss']
})
export class RangeDatePickerComponent {
  @ViewChild('rangeDatePicker') rangeDatePickerElement: ElementRef;
  @Output() startDate = new EventEmitter<Date>();
  @Output() endDate = new EventEmitter<Date>(); 
  startNgbDate: NgbDate | null = null;
  endNgbDate: NgbDate | null = null;
  maxDate: NgbDate;
  hoveredDate: NgbDate | null = null;
  startDateString: string;
  endDateString: string;
  translationWidth = TranslationWidth.Narrow;
  showDatePicker = false;
  months = [ 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  isMobile = window.innerWidth < 1140;
  initialDate = this.calendar.getToday();

  constructor(
    private calendar: NgbCalendar,
    private renderer: Renderer2) {
    this.maxDate = calendar.getToday();
    
    this.renderer.listen('window', 'click', (e:Event) => {
      if(!this.rangeDatePickerElement.nativeElement.contains(e.target)){
          this.showDatePicker = false;
      }
    });

    this.renderer.listen('window', 'resize', (e:Event) => {
      this.isMobile = window.innerWidth < 1140;
    });

    if(!this.isMobile) {
      this.initialDate = this.calendar.getPrev(this.calendar.getToday(), 'm');
    }
  }

  onDateSelection(date: NgbDate) {
    if (date != undefined && date.day > 1 && date.month > 1 && date.year > 1){
      if (!this.startNgbDate && !this.endNgbDate) {
        this.startNgbDate = date;
        this.startDateString = `${date.day} ${this.months[date.month -1]} ${date.year}`;
      } else if (this.startNgbDate && !this.endNgbDate && date.after(this.startNgbDate)) {
        this.endNgbDate = date;
        this.endDateString = `${date.day} ${this.months[date.month -1]} ${date.year}`;
      } else {
        this.endNgbDate = null;
        this.endDateString = '';
        this.startNgbDate = date;
        this.startDateString = `${date.day} ${this.months[date.month -1]} ${date.year}`;
      }
  
      this.startDate.emit(new Date(this.startNgbDate.year, this.startNgbDate.month -1, this.startNgbDate.day));
      this.endDate.emit(this.endNgbDate ? new Date(this.endNgbDate.year, this.endNgbDate.month -1, this.endNgbDate.day) : new Date());
      }
    }
    

  isSelected(date: NgbDate) {
    return date === this.startNgbDate || date === this.endNgbDate;
  }

  isHovered(date: NgbDate) {
    return this.hoveredDate === date;
  }

  showLeftConnector(date: NgbDate) {
    if(this.calendar.getWeekday(date) === 7){
      return false;
    }

    if(date === this.startNgbDate) {
      return false;
    }

    if(date.before(this.startNgbDate) || date.after(this.endNgbDate)){
      return false;
    }

    return this.hasSelectedDateRange();
  }

  showRightDateRangeConnector(date: NgbDate) {
    if(this.calendar.getWeekday(date) === 6){
      return false;
    }

    if(date === this.endNgbDate) {
      return false;
    }

    if(date.before(this.startNgbDate) || date.after(this.endNgbDate)){
      return false;
    }

    return this.hasSelectedDateRange();
  }

  showWeekEnd(date: NgbDate) {
    if(!this.hasSelectedDateRange()) {
      return false;
    }

    if(date.before(this.startNgbDate) || date.after(this.endNgbDate)){
      return false;
    }

    return this.calendar.getWeekday(date) === 6 || this.calendar.getWeekday(date) === 7;;
  }

  private hasSelectedDateRange() {
    return this.startNgbDate && this.endNgbDate;
  }

  clearStartDateWhenEmpty() {
    if(!this.startDateString) {
      this.startDate.emit(undefined);
      this.startNgbDate = null;
    }
  }

  clearEndDateWhenEmpty() {
    if(!this.endDateString) {
      this.endDate.emit(undefined);
      this.endNgbDate = null;
    }
  }
}
