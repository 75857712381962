import { Component, Input, OnInit, Type } from '@angular/core';
import { map, Observable, of, Subject, switchMap, take } from 'rxjs';
import {
  CallToActionHandlerService
} from './call-to-action-handler.service';

export interface CallToActionConfig {
  show: boolean;
  handler?: Type<CallToActionHandler>;
  primaryButton: boolean;
  secondaryButton: boolean;
  disabled: boolean;
  primaryText: string;
  secondaryText: string;
}

export class CallToActionHandler {
  private callToActionOverride = new Subject<CallToActionConfig>();
  
  onSecondaryClicked$(): Observable<boolean> {
    return of(true);
  }

  onPrimaryClicked$(): Observable<boolean> {
    return of(true);
  }

  onOverrideConfig$(): Observable<CallToActionConfig> {
    return this.callToActionOverride;
  }

  updateCallToActionConfig(config: CallToActionConfig) {
    this.callToActionOverride.next(config);
  }
}

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss'],
})
export class CallToActionComponent implements OnInit{
  @Input() config: CallToActionConfig = {
    show: true,
    primaryButton: true,
    secondaryButton: true,
    disabled: true,
    primaryText: '',
    secondaryText: ''
  };

  private readonly defaultHandler: CallToActionHandler = new CallToActionHandler();
  constructor(
    private callToActionInjector: CallToActionHandlerService
  ) {}

  ngOnInit(): void {
    this.callToActionInjector.invokeSubscription$()
      .pipe(
        map(() => this.getHandler()),
        switchMap((handler) => handler.onOverrideConfig$())
      )
      .subscribe(configOverride => {
        this.config = {
          ...this.config,
          show: configOverride?.show ?? this.config.show,
          primaryButton: configOverride?.primaryButton ?? this.config.primaryButton,
          secondaryButton: configOverride?.secondaryButton ?? this.config.secondaryButton,
          primaryText: configOverride?.primaryText ?? this.config.primaryText,
          secondaryText: configOverride?.secondaryText ?? this.config.secondaryText,
          disabled: configOverride?.disabled ?? this.config.disabled
        };
      });
  }

  secondaryClicked() {
    const handler = this.getHandler();
    handler.onSecondaryClicked$().pipe(take(1)).subscribe();
  }

  primaryClicked() {
    const handler = this.getHandler();
    handler.onPrimaryClicked$().pipe(take(1)).subscribe();
  }

  private getHandler<T extends CallToActionHandler>() {
    return (
      this.callToActionInjector.currentCallToActionHandler ?? this.defaultHandler
    );
  }
}
