import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { AdminSettingHandlerService } from '../admin-setting-widget/admin-setting-handler.service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AdminWidgetGuard implements CanActivate {
  constructor(
    private accountService: AccountService,
    private adminSettingService: AdminSettingHandlerService,
    private router: Router
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const includeGHA = route.data['includeGHA'] as boolean;
    const redirectUrl = route.data['redirectUrl'] as string;
    return this.adminSettingService.retrieve$().pipe(
      switchMap((settings) => {
        return this.accountService.getAccountInfo$().pipe(
          map((account) => {
            if(account) {
              let adminRoles = settings.adminRole;

              if(includeGHA) {
                adminRoles = adminRoles.concat(settings.adminRoleGHA);
              }

              const adminRole = account?.roleCodes?.find(role => adminRoles.includes(role));
              if(adminRole) {
                return true;
              }
            }

            this.router.navigate([redirectUrl]);
            return false;
          })
        )
      })
    );
  }
}
