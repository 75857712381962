import { CallToActionConfig, CallToActionHandler } from './../call-to-action/call-to-action.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, Observable, of, Subject } from 'rxjs';
import { ApplicationStateService, Passenger } from './application-state.service';

export interface PassengerDeclaration {
  key: string,
  declaration1: boolean,
  declaration2: boolean,
  declaration3: boolean
}

@Injectable({
  providedIn: 'root',
})
export class HealthDeclarationService extends CallToActionHandler {
  passengerDeclarationsFormGroup: FormGroup;
  passengerDeclarations: PassengerDeclaration[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private applicationStateService: ApplicationStateService,
    private httpClient: HttpClient
  ) {
    super();
    let passengerArray: FormGroup[] = [];
    this.applicationStateService.booking$.subscribe((booking) => {
      booking.passengers.forEach((passenger) => {
        passengerArray.push(
          this.mapPassengerToPassengerDeclarationForm(passenger)
        );
      });
    });
    this.passengerDeclarationsFormGroup = this.formBuilder.group({
      passengers: this.formBuilder.array(passengerArray),
    });
  }
  primaryButtonText: Subject<string>;
  setPrimaryText(text: string): Observable<string> {
    throw new Error('Method not implemented.');
  }
  private mapPassengerToPassengerDeclarationForm(passenger: Passenger) {
    return this.formBuilder.group({
      key: [passenger.key],
      firstName: [passenger.firstName],
      declaration1: ['', Validators.required],
      declaration2: ['', Validators.required],
      declaration3: ['', Validators.required],
    });
  }

  storeDeclaration(): Observable<boolean> {
    this.passengerDeclarations = [];
    for (let form of (
      this.passengerDeclarationsFormGroup.get('passengers') as FormArray
    ).controls) {
      this.passengerDeclarations.push({
        key: form.value.key,
        declaration1: form.value.declaration1,
        declaration2: form.value.declaration2,
        declaration3: form.value.declaration3,
      });
    }
    return of(true);
  }

  submit$(): Observable<boolean> {
    let declarations: PassengerDeclaration[] = [];
    for (let form of (
      this.passengerDeclarationsFormGroup.get('passengers') as FormArray
    ).controls) {
      declarations.push({
        key: form.value.key,
        declaration1: form.value.declaration1 === 'true',
        declaration2: form.value.declaration2 === 'true',
        declaration3: form.value.declaration3 === 'true',
      });
    }
    alert(declarations[0].key);
    return this.httpClient
      .post<boolean>('/healthdeclaration', declarations)
      .pipe(map((response) => response));
  }
}
