<div class="tw-grid tw-grid-cols-10 tw-border tw-border-scoot-black-100 tw-font-bold">
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-3 tw-border tw-border-r-scoot-black-100">Aircraft Type</div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-3 tw-border tw-border-r-scoot-black-100">Class</div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-3 tw-border tw-border-r-scoot-black-100">Seat Availability</div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-1"></div>
</div>
<div *ngFor="let item of seatAvailabilityItems; index as i;" class="tw-grid tw-grid-cols-10 tw-border tw-border-scoot-black-100 tw-border-t-0">
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-3 tw-border tw-border-r-scoot-black-100">
    <input type="text" [(ngModel)]="item.aircraftType" class="tw-w-24" (change)="onChange.emit()"/>
  </div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-3 tw-border tw-border-r-scoot-black-100">
    <div>
      <ng-select
        class="scoot-select"
        [clearable]="false"
        [items]="classItems"
        [(ngModel)]="item.class"
        bindLabel="name"
        bindValue="code"
        (change)="onChange.emit()">
      </ng-select>
    </div>
  </div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-3 tw-border tw-border-r-scoot-black-100">
    <input type="number" [(ngModel)]="item.available" class="tw-w-24" (change)="onChange.emit()"/>
  </div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-1"><img src="/assets/images/tag-delete.svg" (click)="RemoveItem(i)" class="tw-cursor-pointer"/></div>
</div>
<div class="tw-flex tw-justify-end tw-mt-[20px]">
  <button type="button" class="scoot-button scoot-button--primary" (click)="AddItem()">Add Row</button>
</div>