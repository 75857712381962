import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface QueueAssignmentItem {
  priorityCode: string,
  queueCode: string
}

@Component({
  selector: 'app-queue-assignment-table',
  templateUrl: './queue-assignment-table.component.html',
  styleUrls: ['./queue-assignment-table.component.scss']
})
export class QueueAssignmentTableComponent {
  @Input() queueAssignmentItems: QueueAssignmentItem[];
  @Output() onChange = new EventEmitter<null>();

  constructor() { }

  AddItem() {
    this.queueAssignmentItems.push({} as QueueAssignmentItem);
    this.onChange.emit();
  }

  RemoveItem(index: number) {
    this.queueAssignmentItems.splice(index, 1);
    this.onChange.emit();
  }
}
