import { Component, OnInit } from '@angular/core';
import { PageLoaderService } from '../services/page-loader.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent {
  showLoader: boolean = false;
  constructor(
    private pageLoaderService: PageLoaderService
  ) { 
    this.pageLoaderService.isLoading$.subscribe((loading) => this.showLoader = loading)
  }
}
