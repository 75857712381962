<h1 class="tw-text-4 tw-mb-5 lg:tw-text-4xl lg:tw-mb-7.5">
  Pop in your details
</h1>
<form *ngIf="passengersFormGroup" [formGroup]="passengersFormGroup" novalidate>
  <ngb-accordion [closeOthers]="true" [destroyOnHide]="false"
    activeIds="passenger-accordion-1" formArrayName="passengers">
    <ngb-panel id="passenger-accordion-{{ i + 1 }}" *ngFor="let passenger of passengerArray.controls; let i = index">
      <ng-template ngbPanelTitle>
        <div class="tw-flex tw-justify-between tw-w-[97%]">
          <div class="tw-text-1xl">
            {{ passenger.value.firstName }} {{ passenger.value.lastName }}
          </div>
          <img *ngIf="passenger.valid" src="/assets/images/form-complete-check.svg" />
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="scoot-accordion--content" [formGroupName]="i">
          <div class="tw-text-0xl tw-font-bold tw-mb-5">Passenger Details</div>
          <div>
            <div class="tw-flex tw-flex-wrap tw-text-0xl tw-gap-y-2.5 lg:tw-gap-x-10 lg:tw-gap-y-5">
              <div class="tw-flex-grow-1 tw-shrink-0 tw-w-full lg:tw-w-[29%]">
                <div>Title</div>
                <div class="tw-text-1xl">{{ passenger.value.title }}</div>
              </div>
              <div class="tw-flex-grow-1 tw-shrink-0 tw-w-full lg:tw-w-[29%]">
                <div>First Name (Given)</div>
                <div class="tw-text-1xl">{{ passenger.value.firstName }}</div>
              </div>
              <div class="tw-flex-grow-1 tw-shrink-0 tw-w-full lg:tw-w-[29%]">
                <div>Last Name (Surname)</div>
                <div class="tw-text-1xl">{{ passenger.value.lastName }}</div>
              </div>
              <div class="tw-flex-grow-1 tw-shrink-0 tw-w-full lg:tw-w-[29%]">
                <div>Date of Birth</div>
                <div class="tw-text-1xl">
                  {{ passenger.value.dateOfBirth | date: "dd MMM YYYY" }}
                </div>
              </div>
              <div class="tw-flex-grow-1 tw-shrink-0 tw-w-full lg:tw-w-[29%]">
                <div>Country of Birth</div>
                <div class="tw-text-1xl">
                  <ng-select 
                    class="scoot-select tw-mr-5" 
                    [ngClass]="{'tw-border-b-scoot-red-error': !passengerInputValid(i, 'required', 'countryOfBirth')}" 
                    id="country-dropdown-{{ i }}" 
                    placeholder="Select Country" 
                    formControlName="countryOfBirth"
                    [clearable]="false" 
                    [items]="countries" 
                    bindLabel="name" 
                    bindValue="code"
                    appendTo="body">
                  </ng-select>
                </div>
                <div class="tw-text-1 tw-text-scoot-red-error tw-font-medium"
                  *ngIf="!passengerInputValid(i, 'required', 'countryOfBirth')">Required</div>
              </div>
              <div class="tw-flex-grow-1 tw-shrink-0 tw-w-full lg:tw-w-[29%]">
                <div>Nationality</div>
                <div class="tw-text-1xl">
                  <ng-select 
                    class="scoot-select tw-mr-5" 
                    [ngClass]="{'tw-border-b-scoot-red-error': !passengerInputValid(i,'required','nationality')}" 
                    id="nationality-dropdown-{{ i }}" 
                    placeholder="Select Nationality" 
                    formControlName="nationality"
                    [clearable]="false" 
                    [items]="countries"
                    bindLabel="name" 
                    bindValue="code"
                    appendTo="body">
                  </ng-select>
                </div>
                <div class="tw-text-1 tw-text-scoot-red-error tw-font-medium"
                  *ngIf="!passengerInputValid(i, 'required', 'nationality')">Required</div>
              </div>
              <div class="tw-flex-grow-1 tw-shrink-0 tw-w-full lg:tw-w-[29%]">
                <div>Passport Number</div>
                <div>
                  <input 
                    class="tw-text-1xl tw-font-medium tw-border-b tw-border-b-scoot-black-100 tw-w-full" 
                    [ngClass]="{'tw-border-b-scoot-red-error': !passengerInputValid(i, 'required', 'passportNumber')}"
                    type="text"
                    id="passportNumber"
                    formControlName="passportNumber"
                    placeholder="Enter Number" />
                </div>
                <div class="tw-text-1 tw-text-scoot-red-error tw-font-medium"
                  *ngIf="!passengerInputValid(i, 'required', 'passportNumber')">
                  Required
                </div>
                <div class="tw-text-1 tw-text-scoot-red-error tw-font-medium"
                  *ngIf="!passengerInputValid(i, 'pattern', 'passportNumber')">
                  Enter a valid passport number
                </div>
              </div>
              <div class="tw-flex-grow-1 tw-shrink-0 tw-w-full lg:tw-w-[28%]">
                <div>Passport Expiry Date</div>
                <div class="tw-flex">
                  <ng-select 
                    class="scoot-select tw-mr-5" 
                    [ngClass]="{'tw-border-b-scoot-red-error': !passengerInputValid(i, 'required', 'passportExpiryDay', 'passportExpiryMonth', 'passportExpiryYear')}"
                    id="day-dropdown-{{ i }}" 
                    placeholder="DD" 
                    formControlName="passportExpiryDay"
                    [clearable]="false" 
                    [items]="days" 
                    bindLabel="label" 
                    bindValue="value"
                    appendTo="body">
                  </ng-select>
                  <ng-select 
                    class="scoot-select tw-mr-5" 
                    [ngClass]="{'tw-border-b-scoot-red-error': !passengerInputValid(i, 'required', 'passportExpiryDay', 'passportExpiryMonth', 'passportExpiryYear')}" 
                    id="month-dropdown-{{ i }}" 
                    placeholder="MMM" 
                    formControlName="passportExpiryMonth"
                    [clearable]="false" 
                    [items]="months" 
                    bindLabel="label" 
                    bindValue="value"
                    appendTo="body">
                  </ng-select>
                  <ng-select
                    class="scoot-select tw-mr-5" 
                    [ngClass]="{'tw-border-b-scoot-red-error': !passengerInputValid(i, 'required', 'passportExpiryDay', 'passportExpiryMonth', 'passportExpiryYear')}"
                    id="year-dropdown-{{ i }}" 
                    placeholder="YYYY" 
                    formControlName="passportExpiryYear"
                    [clearable]="false" 
                    [items]="years" 
                    bindValue="value" 
                    appendTo="body">
                  </ng-select>
                </div>
                <div class="tw-text-1 tw-text-scoot-red-error tw-font-medium" *ngIf="!passengerInputValid(i, 'required', 'passportExpiryDay', 'passportExpiryMonth', 'passportExpiryYear')">
                  Required
                </div>
                <div class="tw-text-1 tw-text-scoot-red-error tw-font-medium" *ngIf="!passengerFormValid(i, 'expiryIsLessThanSix')">
                  Passport validity needs to be at least 6 months from the date of departure
                </div>
                <div class="tw-text-1 tw-text-scoot-red-error tw-font-medium" *ngIf="!passengerFormValid(i, 'expiryIsLessThanOne')">
                  Passport validity needs to be at least 1 month from the date of departure
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <h2 class="tw-text-3 tw-mt-5 lg:tw-text-3xl">Passport, Visa and Entry Requirement</h2>
  <div class="scoot-card tw-mt-5 lg:tw-col-span-3" [class.has-error]="acknowledgementInvalid">
    <div *ngIf="acknowledgementInvalid" class="tw-text-3.25 tw-text-red-600 tw-mb-0.75">
      Acknowledgement is required
    </div>
    <div>
      <input type="checkbox" id="acknowledge" formControlName="acknowledgement" style="visibility:hidden; position:absolute;"/>
      <label for="acknowledge" class="tw-flex">
        <div class="checkbox-icon"></div>
        <div class="tw-text-3.25">
          Acknowledgement is required are correct, and I've read and accept the following:
          <ul>
            <li>It is your personal responsibility to ensure that you have the valid travel document (such as passport visas, transit visa, custom documents etc.) prior to your departure that complies with all the requirements of Scoot, immigration and other government authorities of every destination. Entry may be refused if you do not comply.</li>
            <li>If you require a visa to travel to a particular country, or require a transit visa if you are transiting via another destination airport, you need to contact the embassy of the country you are visiting or transiting</li>
            <li>All passengers on internation flights must have their travel documents (including the web travel document if applicable) checked and verified at a dedicated Scoot check-in counter, before going through the airport.</li>
            <li>If you fail to comply with the above travel requirements, Scoot reserves the right to cancel your reservation without any refund and prevent you from boarding</li>
          </ul>
        </div>
      </label>
    </div>
  </div>
</form>