import { Inject, Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { TokenService } from './services/token.service';
import { environment } from 'src/environments/environment';

export const SKIP_AUTHORIZATION = new HttpContextToken(() => false);
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    @Inject('baseUrl') 
    private baseUrl: string,
    private tokenService: TokenService
  ) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let url = request.url.toLowerCase().includes("https") ?  request.url : `${this.baseUrl}${request.url}`;
    const setHeaders = { 'Content-Type': 'application/json', 'x-apikey': environment.apikey }; 
    const skipAuthorization = request.context.get(SKIP_AUTHORIZATION);
    if (skipAuthorization) {
      return next.handle(request.clone({ url , setHeaders }));
    }

    return this.tokenService.getToken$().pipe(
      switchMap((token) => {
        return next.handle(request.clone({
          url: url,
          setHeaders: {
            ...setHeaders,
            Authorization: token
          }
        }));
      })
    );
  }
}
