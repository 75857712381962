import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ItineraryService } from 'src/app/services/itinerary.service';
import { RouterService } from 'src/app/services/router.service';
import { QueueNoticeDialogService } from './queue-notice-dialog.service';

@Component({
  selector: 'app-queue-notice-dialog',
  templateUrl: './queue-notice-dialog.component.html',
  styleUrls: ['./queue-notice-dialog.component.scss']
})
export class QueueNoticeDialogComponent implements OnInit {
  public proceed: FormControl;
  show: boolean = true;
  totalPassengersForCheckIn: number;
  constructor(private queueNoticeDialogService: QueueNoticeDialogService,
              private itineraryService: ItineraryService,
              private router: RouterService) { }

  ngOnInit(): void {
    this.proceed = new FormControl();
    this.queueNoticeDialogService.displayDialog.subscribe(value => this.show = value);
    this.queueNoticeDialogService.passengersToCheckIn.subscribe(value => this.totalPassengersForCheckIn = value);
  }

  closeNotice() : void {
    this.show = false;
  }

  process() : void {
    if (this.proceed.value === "false") {

      const passengerKeys = this.itineraryService.passengerItinerary.passengers
        .filter((pax) => pax.canCheckIn)
        .map((pax) => pax.key);

      this.itineraryService.passengerItinerary.passengers.forEach(passenger => {
        passenger.canCheckIn = false
        passenger.queueDisplay = passenger.queue;
      });
      this.itineraryService.removeCheckInStatus(passengerKeys);
    }
    else {
      console.log('continue to passenger page')
      this.itineraryService.setPassengersForCheckIn();
      this.itineraryService.proceedWithPartialCheckIn$.next();
    }
    this.show = false;
  }

  formatQueueNumber(queue: string|null) : string {
    if (queue !== "" && queue !== null) {
      console.log('returning with q')
      return `Q: ${queue}`;
    }
    return "";
  }
}
