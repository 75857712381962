import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, shareReplay } from 'rxjs';
import { SKIP_AUTHORIZATION } from '../api.interceptor';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private token$: Observable<string>;

  constructor(private httpClient: HttpClient) {
    this.token$ = this.httpClient.get<{ token: string }>('/token', { context: new HttpContext().set(SKIP_AUTHORIZATION, true) })
      .pipe(
        map((response) => response.token),
        shareReplay(1)
      );
  }

  getToken$(): Observable<string> {
    return this.token$;
  }

  setToken(token: string) {
    this.token$ = of(token);
  }
}
