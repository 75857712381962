<div class="tw-fixed tw-top-0 tw-w-full tw-h-[70px] tw-z-10">
  <app-header></app-header>
</div>
<div class="tw-mx-3.75 lg:tw-mx-37.5 lg:tw-mt-24 tw-mb-[100px] tw-mt-20">
  <router-outlet></router-outlet>
</div>
<ng-container *ngIf="this.showCallToAction">
  <div class="tw-fixed tw-bottom-0 tw-w-full tw-h-[70px]">
    <app-call-to-action
      [config]="this.callToActionConfig!"
    ></app-call-to-action>
  </div>
</ng-container>
<!-- <app-footer></app-footer> -->
<app-page-loader></app-page-loader>
