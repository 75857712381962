import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { defer, Observable, of } from 'rxjs';
import { Location } from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(private router: Router, private location: Location) {}

  next$(path: string): Observable<boolean> {
    return defer(() => this.router.navigate([path]));
  }

  previous$(): Observable<boolean> {
    this.location.back();
    return of(true);
  }
}
