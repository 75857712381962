import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueueNoticeDialogService {
  public displayDialog = new BehaviorSubject<boolean>(false);
  public passengersToCheckIn = new BehaviorSubject<number>(0);
  constructor() { }

  setPassengersAllowedForCheckIn(passengers: number) {
    this.passengersToCheckIn.next(passengers);
  }
  show() {
    this.displayDialog.next(true);
  }

  hide() {
    this.displayDialog.next(false);
  }
}
