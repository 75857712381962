import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-alternative-email',
  templateUrl: './alternative-email.component.html',
  styleUrls: ['./alternative-email.component.scss']
})
export class AlternativeEmailComponent {
  @Input() alternativeEmailControl: FormControl;
  constructor() { }

  get errorMessage(){
    return this.alternativeEmailControl.value === '' ? 'Required' : 'Invalid email address';
  }
}
