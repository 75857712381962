import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminHomeWidgetComponent } from './admin-home-widget/admin-home-widget.component';
import { AdminLoginWidgetComponent } from './admin-login-widget/admin-login-widget.component';
import { AdminSettingWidgetComponent } from './admin-setting-widget/admin-setting-widget.component';
import { AdminStandbyHistoryWidgetComponent } from './admin-standby-history-widget/admin-standby-history-widget.component';
import { AdminWidgetComponent } from './admin-widget/admin-widget.component';
import { CallToActionConfig } from './call-to-action/call-to-action.component';
import { ConfirmationWidgetComponent } from './confirmation-widget/confirmation-widget.component';
import { ErrorWidgetAdminComponent } from './error-widget/error-widget-admin/error-widget-admin.component';
import { ErrorWidgetComponent } from './error-widget/error-widget.component';
import { HealthDeclarationWidgetComponent } from './health-declaration-widget/health-declaration-widget.component';
import { ItineraryWidgetComponent } from './itinerary-widget/itinerary-widget.component';
import { LoginWidgetComponent } from './login-widget/login-widget.component';
import { PassengerWidgetComponent } from './passenger-widget/passenger-widget.component';
import { SafetyAndSecurityWidgetComponent } from './safety-and-security-widget/safety-and-security-widget.component';
import { AdminWidgetGuard } from './services/admin-widget.guard';
import { NotAvailablePageComponent } from './not-available-widget/not-available-widget.component';

const callToActionConfig: CallToActionConfig & { enabled: boolean } = {
  enabled: true,
  show: true,
  handler: undefined,
  primaryButton: true,
  secondaryButton: true,
  disabled: false,
  primaryText: 'Continue',
  secondaryText: 'Back'
};

const routes: Routes = [
  {
    path: 'error',
    component: ErrorWidgetComponent,
    data: {
      callToAction: {
        enabled: false,
      }
    },
  },
  {
    path: 'error-admin',
    component: ErrorWidgetAdminComponent,
    data: {
      callToAction: {
        enabled: false,
      }
    },
  },
  {
    path: 'not-available',
    component: NotAvailablePageComponent,
    data: {
      callToAction: {
        enabled: false,
      }
    },
  },
  {
    path: '',
    component: LoginWidgetComponent,
    data: {
      callToAction: {
        enabled: false,
      }
    },
  },
  {
    path: 'itinerary',
    component: ItineraryWidgetComponent,
    data: {
      callToAction: {
        ...callToActionConfig,
        secondaryButton: false,
      }
    },
  },
  {
    path: 'passenger',
    component: PassengerWidgetComponent,
    data: {
      callToAction: {
        ...callToActionConfig,
      }
    },
  },
  {
    path: 'health-declaration',
    component: HealthDeclarationWidgetComponent,
    data: {
      callToAction: {
        ...callToActionConfig,
        primaryButton: false,
      }
    },
  },
  {
    path: 'safety-and-security',
    component: SafetyAndSecurityWidgetComponent,
    data: {
      callToAction: {
        ...callToActionConfig,
        secondaryButton: false,
      }
    },
  },
  {
    path: 'confirmation',
    component: ConfirmationWidgetComponent,
    data: {
      callToAction: {
        enabled: false,
      }
    },
  },
  {
    path: 'qmp',
    component: AdminWidgetComponent,
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "login", component: AdminLoginWidgetComponent },
      { 
        path: "home", 
        component: AdminHomeWidgetComponent,
        canActivate: [AdminWidgetGuard],
        data: {
          includeGHA : true,
          redirectUrl: '/qmp'
        }
      },
      { 
        path: "standby-history", 
        component: AdminStandbyHistoryWidgetComponent,
        canActivate: [AdminWidgetGuard],
        data: {
          includeGHA : false,
          redirectUrl: '/qmp/home',
          enabled: false,
        }
      },
      { 
        path: "setting", 
        component: AdminSettingWidgetComponent,
        canActivate: [AdminWidgetGuard],
        data: {
          callToAction: {
            ...callToActionConfig,
            enabled: true,
            show: true,
            disabled: true,
            primaryText: 'Save',
            secondaryText: 'Cancel'
          },
          includeGHA : false,
          redirectUrl: '/qmp/home'
        }
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {
  }
}
