<div 
    *ngIf="show"
    class="tw-fixed tw-top-0 tw-z-9000 tw-h-full tw-flex tw-items-center tw-justify-center tw-bg-black-100/80 lg:tw-w-[953px]">
    <div
        class="tw-mx-3.75 tw-w-86.25 tw-rounded-2.5xl tw-bg-white tw-py-7.5 tw-px-3.75 lg:tw-w-120 lg:tw-py-10 lg:tw-px-10">
    <div class="tw-flex tw-flex-col tw-items-center">
        <h2>Notice</h2>
        <ng-container>
            <p class="tw-my-5 tw-text-center tw-text-3.25 lg:tw-mb-6.25 tw-font-bold tw-w-72">
                There is not enough available seat(s) for the total passenger(s) to check-in
            </p>
            <div class="tw-text-0.5 tw-ml-4">
                <label class="tw-mb-0">
                    <input
                        class="tw-mr-1"
                        type="radio"
                        id="continue-check-in"
                        name="queue-continue"
                        value="true"
                        [formControl]="proceed" />
                    
                    <span>Continue to check in for {{totalPassengersForCheckIn}} passenger/s</span>
                </label>
                <label class="tw-mb-0">
                    <input
                        class="tw-mr-1"
                        type="radio"
                        id="cancel-check-in"
                        name="queue-continue"
                        value="false"
                        [formControl]="proceed" />
                    
                    <span>Cancel check in and issue queue number for all passenger(s)</span>
                </label>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-center tw-text-0.9 tw-mt-5">
                <button
                    class="scoot-button scoot-button--secondary tw-mr-4 tw-h-8"
                    (click)="closeNotice()">
                    Cancel
                </button>
                <button
                    class="scoot-button scoot-button--primary tw-h-8"
                    (click)="process()">
                    Continue
                </button>
            </div>
        </ng-container>


        
    </div>
</div>

