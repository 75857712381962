import { CallToActionHandlerService } from '../call-to-action/call-to-action-handler.service';
import { RouterService } from './../services/router.service';
import { CallToActionHandler } from './../call-to-action/call-to-action.component';
import { Component, Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HealthDeclarationService } from '../services/health-declaration.service';
import { PageLoaderService } from '../services/page-loader.service';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-health-declaration-widget',
  templateUrl: './health-declaration-widget.component.html',
  styleUrls: ['./health-declaration-widget.component.scss'],
})
export class HealthDeclarationWidgetComponent extends CallToActionHandler {
  healthDeclarationForm: FormGroup;

  constructor(
    private router: RouterService,
    private healthDeclarationService: HealthDeclarationService,
    private pageLoaderService: PageLoaderService,
    private callToActionRegistry: CallToActionHandlerService
  ) {
    super();
    callToActionRegistry.registerHandler(this);
    this.healthDeclarationForm =
      this.healthDeclarationService.passengerDeclarationsFormGroup;
  }

  declare() {}

  get passengerArray(): FormArray {
    return this.healthDeclarationForm.get('passengers') as FormArray;
  }

  isInvalid(form: AbstractControl, controlName: string): boolean {
    const control = form.get(controlName)!;
    return !control.valid && (control.dirty || control.touched);
  }

  override onPrimaryClicked$(): Observable<boolean> {
    this.healthDeclarationForm.markAllAsTouched();
    this.healthDeclarationForm.markAsDirty();
    const isValid = this.healthDeclarationForm.valid;
    if (isValid) {
      this.pageLoaderService.showPageLoader();
      return this.healthDeclarationService
        .submit$()
        .pipe(switchMap(() => this.router.next$('health-declaration')));
    }
    return of(isValid);
  }
  override onSecondaryClicked$(): Observable<boolean> {
    return this.router.previous$();
  }
}
