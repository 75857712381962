<div 
    *ngIf="show"
    class="tw-fixed tw-top-0 tw-z-9000 tw-h-full tw-flex tw-items-center tw-justify-center tw-bg-black-100/80 lg:tw-w-[953px]">
    <div
        class="tw-mx-3.75 tw-w-86.25 tw-rounded-2.5xl tw-bg-white tw-py-7.5 tw-px-3.75 lg:tw-w-120 lg:tw-py-10 lg:tw-px-10">
    <div class="tw-flex tw-flex-col tw-items-center">
        <h2>Notice</h2>
        <ng-container>
            <p class="tw-my-5 tw-text-center tw-text-3.25 lg:tw-mb-6.25 tw-font-bold tw-w-72">
                Queue Notification sent.
            </p>
            <p class="tw-my-5 tw-text-center tw-text-3.25 lg:tw-mb-6.25 tw-font-bold tw-w-72">
                Please come to the counter at 55minutes before flight departure to check if you are accepted for the flight. 
            </p>
            <div class="tw-flex tw-flex-row tw-justify-center tw-text-0.9 tw-mt-5">
                <button
                    class="scoot-button scoot-button--primary tw-h-8"
                    (click)="process()">
                    OK
                </button>
            </div>
        </ng-container>


        
    </div>
</div>

