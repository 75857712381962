<div *ngIf="staffQueuesWClass.length > 0 && staffQueuesYClass.length > 0" class="tw-flex tw-justify-between tw-rounded-full tw-bg-scoot-black-10 tw-p-[5px] lg:tw-w-[348px] lg:tw-ml-6 lg:tw-mt-8 lg:tw-mb-5 lg:tw-text-0.9 tw-text-0.5 tw-mt-4 tw-mb-5 tw-mx-1">
    <a  class="hover:tw-no-underline">
      <div class="cabin-class-tab business-cabin" [class.selected]="selectedCabin === 'W'" (click)="setSelectedCabin('W')">CABIN CLASS (W)</div>
    </a>
    <a class="hover:tw-no-underline">
      <div class="cabin-class-tab economy-cabin" [class.selected]="selectedCabin === 'Y'" (click)="setSelectedCabin('Y')">CABIN CLASS (Y)</div>
    </a>
</div>
  <div class="tw-hidden lg:tw-block tw-border-b-2 tw-mb-2.5 tw-mx-2.5"></div>
  <div class="tw-hidden lg:tw-flex lg:tw-visible lg:tw-font-bold lg:tw-text-0.9 lg:tw-mb-3">
    <div class="tw-ml-5">Priority Order</div>
    <div class="tw-ml-[79px]">PNR</div>
    <div class="tw-ml-[132px]">Queue No.</div>
    <div class="tw-ml-[102px]">Last Name</div>
    <div class="tw-ml-[98px]">First Name</div>
    <div class="tw-ml-[97px]">Staff Airline</div>
    <div class="tw-ml-[90px]">Reg. Date/Time</div>
  </div>
  <div class="tw-hidden lg:tw-block tw-border-b-2 tw-mb-2.5 tw-mx-2.5"></div>
  <div class="business-cabin tw-mb-6 lg:tw-mb-3" *ngFor="let queue of (selectedCabin === 'W' ? staffQueuesWClass : staffQueuesYClass); let i = index">
    <div class="tw-grid tw-grid-flow-row tw-grid-cols-3 lg:tw-grid-cols-7 tw-ml-5 lg:tw-w-[1154px] tw-mb-4 lg:tw-mb-0">
      <div>
        <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">Priority Order</p>
        <p class="tw-font-bold tw-text-0.9 lg:tw-mr-[3px] lg:tw-font-normal">{{i + 1}}</p>
      </div>
      <div>
        <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">PNR</p>
        <p class="tw-font-bold tw-text-0.9 lg:tw-mr-[3px] lg:tw-font-normal">{{queue.pnr}}</p>
      </div>
      <div>
        <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">Queue No.</p>
        <p [class]="'tw-font-bold tw-text-0.9 lg:tw-mr-[3px] lg:tw-font-normal tw-rounded-2.5xl tw-w-16 tw-text-center tw-h-6 ' + (queue.cabinClass === 'W' ?  'tw-bg-scoot-yellow-100' : 'tw-bg-scoot-blue-10 tw-text-white')">{{queue.number}}</p>
      </div>
      <div>
        <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">Last Name</p>
        <p class="tw-font-bold tw-text-0.9 lg:tw-mr-[3px] lg:tw-font-normal">{{queue.lastName}}</p>
      </div>
      <div class="tw-col-span-2 lg:tw-col-span-1">
        <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">First Name</p>
        <p class="tw-font-bold tw-text-0.9 lg:tw-mr-[3px] lg:tw-font-normal">{{queue.firstName}}</p>
      </div>
      <div>
        <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">Staff Airline</p>
        <p class="tw-font-bold tw-text-0.9 lg:tw-mr-[3px] lg:tw-font-normal">{{queue.airline}}</p>
      </div>
      <div class="tw-col-span-2 lg:tw-col-span-1">
        <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">Reg. Date/Time</p>
        <p class="tw-font-bold tw-text-0.9 lg:tw-mr-[3px] lg:tw-font-normal">{{ queue.registration | date : 'dd/MM/yyyy HH:mm:ss.SSS' }}</p>
      </div>
    </div>
    <div class="tw-border-b-2 tw-mb-2.5 tw-mx-2.5 lg:tw-mb-0"></div>
  </div>
