import { Component, Input, OnInit } from '@angular/core';
import { StaffQueue } from '../admin-home-widget.component';

@Component({
  selector: 'app-admin-queue',
  templateUrl: './admin-queue.component.html',
  styleUrls: ['./admin-queue.component.scss']
})
export class AdminQueueComponent implements OnInit {
  @Input() StaffTravelQueues: StaffQueue[];
  selectedCabin: string;
  hasScootPlusCabin: false;
  hasEconomyCabin: false;
  staffQueuesWClass : StaffQueue[] = [];
  staffQueuesYClass : StaffQueue[] = [];

  ngOnInit(): void {
    this.getCabinQueues();
  }

  getCabinQueues(){
    this.staffQueuesWClass = [...this.StaffTravelQueues
    .filter(q => q.cabinClass === 'W')
    .sort((a,b) => {return this.sortDescending(a.registration, b.registration)})];

    this.staffQueuesYClass = [...this.StaffTravelQueues
      .filter(q => q.cabinClass === 'Y')
      .sort((a,b) => {return this.sortDescending(a.registration, b.registration)})];

    this.selectedCabin = this.staffQueuesWClass.length > 0 ? 'W' : 'Y';
  }

  setSelectedCabin(cabinCode: string){
    this.selectedCabin = cabinCode;
  }

  sortDescending(a: any, b: any)
  {
    return a - b;
  }
}
