<div class="scoot-card-header" *ngIf="scootCardHeaderTemplate">
  <ng-container 
    [ngTemplateOutlet]="scootCardHeaderTemplate"
  >
  </ng-container>
</div>

<div class="scoot-card-content">
    <ng-container [ngTemplateOutlet]="scootCardBodyTemplate"> </ng-container>
</div>

<div class="scoot-card-footer">
  <ng-container [ngTemplateOutlet]="scootCardFooterTemplate"> </ng-container>
</div>
