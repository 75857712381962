import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { combineLatest, map, Observable, tap } from 'rxjs';
import {
  ApplicationStateService,
  Booking,
  Passenger
} from './application-state.service';
import { ItineraryService } from './itinerary.service';
import { ResourceService, Station } from './resource.service';

@Injectable({
  providedIn: 'root',
})
export class PassengerService {
  passengersFormGroup: FormGroup;
  private stations: Station[];

  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private applicationStateService: ApplicationStateService,
    private resourceService: ResourceService,
    private itineraryService: ItineraryService
  ) {

    combineLatest([
      this.applicationStateService.booking$,
      this.itineraryService.passengerItinerary$
    ]).pipe(
      map(([booking, itinerary]) => {
        const passengersForCheckIn = booking.passengers.filter(p => {
          const passengerQueue = itinerary.passengers.find(queue => queue.key === p.key);
          return passengerQueue?.canCheckIn ?? true;
        })
        return {booking, passengersForCheckIn};
      }),
      map(({booking, passengersForCheckIn}) => {
        return passengersForCheckIn.map((passenger) => {
          const formGroup = this.mapPassengerToPassengerForm({
            ...passenger,
            passportExpiryDate: passenger.passportExpiryDate
              ? new Date(passenger.passportExpiryDate)
              : undefined,
          });
          formGroup.addValidators(this.expiryValidator(booking));
          return formGroup;
        })
      })
    ).subscribe(
      (passengers) =>
        (this.passengersFormGroup = this.formBuilder.group({
          passengers: this.formBuilder.array([...passengers]),
          acknowledgement: [ null , Validators.requiredTrue ]
        }))
    );

    // this.applicationStateService.booking$
    //   .pipe(
    //     map((booking) =>
    //       booking.passengers.map((passenger) => {
    //         const formGroup = this.mapPassengerToPassengerForm({
    //           ...passenger,
    //           passportExpiryDate: passenger.passportExpiryDate
    //             ? new Date(passenger.passportExpiryDate)
    //             : undefined,
    //         });

    //         formGroup.addValidators(this.expiryValidator(booking));
    //         return formGroup;
    //       })
    //     )
    //   )
    //   .subscribe(
    //     (passengers) =>
    //       (this.passengersFormGroup = this.formBuilder.group({
    //         passengers: this.formBuilder.array([...passengers]),
    //         acknowledgement: [ null , Validators.requiredTrue ]
    //       }))
    //   );
    this.resourceService.getStations$().subscribe(s => this.stations = s);
  }

  submit$(): Observable<boolean> {
    return this.httpClient
      .post<boolean>('/passenger', this.mapPassengerFormGroupToPassengerArray())
      .pipe(map((response) => response));
  }

  private mapPassengerToPassengerForm(passenger: Passenger): FormGroup {
    return this.formBuilder.group(
      {
        key: [ passenger.key, Validators.required ],
        title: [ passenger.title, Validators.required ],
        firstName: [ passenger.firstName, Validators.required ],
        lastName: [ passenger.lastName, Validators.required ],
        gender: [ passenger.gender, Validators.required ],
        dateOfBirth: [ passenger.dateOfBirth, Validators.required ],
        nationality: [ passenger.nationality, Validators.required ],
        passengerTypeCode: [ passenger.passengerTypeCode, Validators.required ],
        countryOfBirth: [ passenger.countryOfBirth, Validators.required ],
        travelDocumentKey: [ passenger.travelDocumentKey ],
        passportNumber: [ passenger.passportNumber, [Validators.required, Validators.pattern('[A-Za-z0-9]+')] ],
        passportExpiryDay: [ passenger.passportExpiryDate?.getDate(), Validators.required ],
        passportExpiryMonth: [ passenger.passportExpiryDate ? passenger.passportExpiryDate.getMonth() + 1 : undefined, Validators.required ],
        passportExpiryYear: [ passenger.passportExpiryDate?.getFullYear(), Validators.required ],
      },
      {
        updateOn: 'blur',
      }
    );
  }

  private mapPassengerFormGroupToPassengerArray() {
    const passengers: Passenger[] = [];
    for (let passengerForm of (<FormArray>(
      this.passengersFormGroup.get('passengers')
    )).controls) {
      let passportExpiryDateUtc = new Date();
      passportExpiryDateUtc.setUTCFullYear(passengerForm.value.passportExpiryYear);
      passportExpiryDateUtc.setUTCMonth(passengerForm.value.passportExpiryMonth - 1);
      passportExpiryDateUtc.setUTCDate(passengerForm.value.passportExpiryDay);
      passengers.push({
        key: passengerForm.value.key,
        title: passengerForm.value.title,
        firstName: passengerForm.value.firstName,
        lastName: passengerForm.value.lastName,
        gender: passengerForm.value.gender,
        dateOfBirth: passengerForm.value.dateOfBirth,
        countryOfBirth: passengerForm.value.countryOfBirth,
        passengerTypeCode: passengerForm.value.passengerTypeCode,
        nationality: passengerForm.value.nationality,
        travelDocumentKey: passengerForm.value.travelDocumentKey,
        passportExpiryDate: new Date(
          Date.UTC(passengerForm.value.passportExpiryYear, passengerForm.value.passportExpiryMonth - 1, passengerForm.value.passportExpiryDay, 0, 0, 0)
        ),
        passportNumber: passengerForm.value.passportNumber,
        declaration1: false,
        declaration2: false,
        declaration3: false,
        hasOkGo: false,
        hasRestrictedSsr: false,
        isBufferSeat: false,
        queue: "",
        isCheckedIn: false,
        staffInQueue: 0,
        canCheckIn: false,
        queueDisplay: "",
        systemQueue: ""
      });
    }
    return passengers;
  }

  expiryValidator(booking: Booking): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const nationality = control.get('nationality')?.value;
      const expiryDay = control.get('passportExpiryDay')?.value;
      const expiryMonth = control.get('passportExpiryMonth')?.value;
      const expiryYear = control.get('passportExpiryYear')?.value;

      if (!expiryDay || !expiryMonth || !expiryYear) return null;

      const expiry = new Date(expiryYear, expiryMonth - 1, expiryDay);

      const departureDate = new Date(booking.journey.departureDate);
      const arrivalStation = this.stations.find(s => s.code == booking.journey.arrivalStation);
      const returningNational = nationality == arrivalStation?.countryCode;
      const allowedExpiryMonth = returningNational ? 1 : 6;
      const allowedExpiryDate = new Date(
        departureDate.getFullYear(),
        departureDate.getMonth() + allowedExpiryMonth,
        departureDate.getDate()
      );

      return expiry > allowedExpiryDate ? null : { expiryIsLessThanOne: returningNational, expiryIsLessThanSix: !returningNational };
    };
  }
}
