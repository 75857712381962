import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep, isEqual } from 'lodash';
import { Observable, of } from 'rxjs';
import { CallToActionHandlerService } from '../call-to-action/call-to-action-handler.service';
import { CallToActionConfig, CallToActionHandler } from '../call-to-action/call-to-action.component';
import { AdminSettingHandlerService, AdminSettings } from './admin-setting-handler.service';

@Component({
  selector: 'app-admin-setting-widget',
  templateUrl: './admin-setting-widget.component.html',
  styleUrls: ['./admin-setting-widget.component.scss']
})
export class AdminSettingWidgetComponent extends CallToActionHandler {
  settingsLoaded: boolean;
  showConfirmChanges: boolean;
  settings: AdminSettings;
  currentlyAppliedSettings: AdminSettings;

  constructor(
    private router: Router,
    private adminSettingService: AdminSettingHandlerService,
    callToActionRegistry: CallToActionHandlerService
  ) { 
    super();
    callToActionRegistry.registerHandler(this);
    this.adminSettingService.retrieve$()
      .subscribe(adminSettings => {
        this.settings = cloneDeep(adminSettings);
        this.currentlyAppliedSettings = cloneDeep(adminSettings);
        this.settingsLoaded = true;
        this.hasCurrentChange();
      });
  }

  update(){   
    this.adminSettingService.update$(this.settings!)
      .subscribe(result => { 
        if(!result) {
          this.router.navigate(['error']);
        }

        this.currentlyAppliedSettings = cloneDeep(this.settings);
        this.showConfirmChanges = false;
        this.hasCurrentChange();
      });
  }

  mustBeThreeCharacters(input: string) : boolean {
    return input.length === 3;
  }

  mustBeRouteFormat(input: string) : boolean {
    const stations = input.split('-');
    return input.length === 7 && stations[0].length === 3 && stations[1].length === 3;
  }

  override onPrimaryClicked$(): Observable<boolean> {
    this.showConfirmChanges = true;
    return of(false);
  }

  override onSecondaryClicked$(): Observable<boolean> {
    this.settings = cloneDeep(this.currentlyAppliedSettings);
    this.hasCurrentChange();
    return of(false);
  }

  hasCurrentChange() {
    const noChange = isEqual(this.settings, this.currentlyAppliedSettings);
    this.updateCallToActionConfig({disabled: noChange, show: true} as CallToActionConfig);
  }
}
