<h1 class="tw-text-4 lg:tw-text-4xl tw-mb-5">Staff travel</h1>

<div class="tw-mb-2.5">
  <h2 class="tw-text-3 lg:tw-text-3xl">Web check-in</h2>
  <div class="tw-text-3 lg:tw-text-2xl" *ngIf="timetable">
    Ex SIN: Available between {{ timetable.singaporeOpenTime / 60 | number}} hours and
    {{ timetable.closeTime }} mins
  </div>
  <div class="tw-text-3 lg:tw-text-2xl" *ngIf="timetable">
    Ex STATIONS: Available between {{ timetable.openTime / 60 | number}} hours and
    {{ timetable.closeTime }} mins
  </div>
</div>

<div class="tw-mb-2.5 lg:tw-mb-5">
  <h2 class="tw-text-3 lg:tw-text-3xl">If you're successfully checked in</h2>
  <div class="tw-text-3 lg:tw-text-2xl">
    Collect your boarding pass at Airport
  </div>
  <!-- <div class="tw-text-3 lg:tw-text-2xl">
    Ex SIN: Collect your boarding pass at Self-Service Kiosk (SSK)
  </div>
  <div class="tw-text-3 lg:tw-text-2xl tw-mb-">
    Ex STATIONS: Collect your boarding pass at Airport
  </div> -->
</div>

<div class=" tw-w-[345px] lg:tw-w-[560px] tw-p-5 tw-pl-0 tw-mb-2">
  <p class=" tw-text-3 lg:tw-text-2xl tw-m-0 tw-pb-2">
    As a staff, you are reminded that you are not to utilise staff travel while on Sick Leave or Wellness Leave
  </p>

</div>


<div class="scoot-card tw-w-[345px] lg:tw-w-[560px]">

  <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
    <div *ngIf="errorMessage" class="tw-text-0xl tw-m-0 tw-mb-0.75 tw-text-red-600" [innerHTML]="errorMessage"></div>
    <div class="tw-mb-5 lg:tw-mb-[0.375rem]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75" for="bookingReference">Booking Reference (PNR)</label>
      <input
        class="tw-text-3 lg:tw-text-1xl tw-border-b tw-border-b-scoot-black-100 tw-w-full"
        type="text"
        id="bookingReference"
        placeholder="Enter Your Booking Reference"
        formControlName="bookingReference"
        [class.is-invalid]="isInvalid('bookingReference')"
      />
    </div>
    <div class="tw-mb-5 lg:tw-mb-[2.125rem]">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75" for="departing">Departure Station</label>
      <div>
        <ng-select
          class="scoot-select"
          id="departing-dropdown"
          placeholder="Select Airport"
          formControlName="departing"
          [clearable]="false"
          [items]="stationViews"
          bindLabel="name"
          bindValue="code"
          appendTo="#departing-dropdown"
        [searchFn]="stationViewsSearchFn">

          <ng-template  ng-option-tmp let-item="item" let-search="searchTerm">
            <p [ngOptionHighlight]="search">{{item.name}} ({{item.code}})</p>
            <p [ngOptionHighlight]="search" class="small">{{item.country?.name}}</p>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="tw-flex tw-justify-end">
      <button class="scoot-button scoot-button--primary" type="submit">
        Register
        <svg
          width="24"
          height="16"
          viewBox="0 0 24 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="tw-leading-zero tw-fill-current tw-text-white tw-ml-2.5">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16 0L14.59 1.41L20.17 7H0V9H20.17L14.59 14.59L16 16L24 8L16 0Z"/>
        </svg>
      </button>
    </div>
  </form>
</div>
