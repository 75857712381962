<h1 class="tw-text-4 lg:tw-text-4xl tw-mb-5">Before you fly</h1>
<div class="tw-flex tw-flex-col lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-x-7.5 tw-mb-14">
  <div class="tw-grid tw-grid-cols-1 tw-mb-5">
    <img class="holidays-dude tw-place-self-center lg:tw-w-[362px] lg:tw-h-[332px]"
         src="/assets/images/many-holidays-dude.svg"/>
  </div>

  <div class="scoot-card lg:tw-col-span-1 tw-text-3.25">
    <div class="header">
      Visa Requirements & COVID-19 Advisory
    </div>
    <p>Due to the changing COVID-19 situation, travel regulations may get updated frequently.</p>
    <p>It is your personal responsibility to ensure that you have the valid travel documents for your specific destination</p>
    <p>Travel documentation might include:</p>
    <ul>
      <li>Passport</li>
      <li>Visas</li>
      <li>Transit visa</li>
      <li>Custom documents</li>
      <li>Hotel quarantine booking reference</li>
      <li>Relevant vaccination documents</li>
    </ul>
    <a class="tw-text-scoot-blue-20" href="https://help.flyscoot.com/s/topic/0TO0K000000MqZyWAK/covid19" target="_blank">More info here</a>
  </div>

  <div class="scoot-card lg:tw-col-span-1 tw-text-3.25">
    <div class="header">Prohibited Goods</div>
    <p>For your safety and convenience, do not carry the following dangerous goods inside cabin or checked baggages.</p>
    <a class="tw-text-scoot-blue-20" href="https://www.flyscoot.com/en/fly-scoot/before-you-fly/travel-information#dangerous-goods" target="_blank">More info here</a>
    <img class="tw-my-2.5" src="/assets/images/prohibited-goods.svg"/>
    <ul>
      <li>Explosives</li>
      <li>Oxidizing materials</li>
      <li>Radioactive materials</li>
      <li>Compressed Gas</li>
      <li>Flammable Gases/ Liquid/Solid</li>
      <li>Toxic / Infectious subtance</li>
      <li>Corrosive materials</li>
      <li>Lithium batteries > 160 Wh or 8g</li>
      <li>Disabling Devices (pepper spray)</li>
      <li>Personal transportation / Mobility devices</li>
      <li>Meals-Ready-to-Eat</li>
    </ul>
  </div>

  <div class="scoot-card lg:tw-col-span-3 lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-x-16">
    <div>
      <div class="tw-mb-5 tw-text-3.25">
        <div class="header">Cabin Baggage</div>
        <p>All cabin baggage must fit the specific dimensions. You will need to purchase check-in baggage allowance if it exceeds the restrictions.</p>
        <a class="tw-text-scoot-blue-20" href="https://www.flyscoot.com/en/fly-scoot/before-you-fly/baggage#cabin-baggage" target="_blank">More info here</a>
      </div>

      <div class="tw-mb-5 tw-text-3.25">
        <div class="header">
          Cabin Baggage Amount Limit
        </div>
        Each passenger is limited to 2 pieces of cabin baggage.
        <img class="tw-my-2.5" src="/assets/images/cabin-baggages.svg"/>
      </div>
    </div>

    <div>
      <div class="tw-mb-5 tw-text-3.25">
        <div class="header">
          Cabin Baggage Size Limit
        </div>
        <p>One piece of the cabin bag must not exceed dimensions of 54cm X 38cm X 23cm and must fit the overhead compartment in the aircraft cabin.</p>
        <p>The other smaller cabin baggage must not exceed the dimensions of 40cm X 30cm X 10cm and must be able to fit under the seat in front of you.</p>
      </div>

      <div class="tw-mb-5 tw-text-3.25">
        <div class="header">
          Cabin Baggage Weight Limit
        </div>
        <p>Maximum total combined weight allowed based on seat type:</p>
        <div class="baggage-weight-table tw-font-bold tw-mt-2 tw-text-3.25">
          <div class="tw-bg-scoot-yellow-20">Economy</div>
          <div class="tw-bg-scoot-yellow-20">10kg</div>
          <div class="tw-bg-scoot-yellow-100">Scoot Plus</div>
          <div class="tw-bg-scoot-yellow-100">15kg</div>
        </div>
      </div>
    </div>

    <div>
      <div class="tw-mb-5 tw-text-3.25">
        <div class="header">
          Liquids, Aerosols & Gels in Cabin Baggage
        </div>
        <img class="tw-my-2.5" src="/assets/images/liquids.svg"/>
        <ul>
          <li>1 bag per person</li>
          <li>Liquids, aerosols and gels (max 100ml each)</li>
          <li>Exemptions for medications, baby food and special dietary items</li>
          <li>1 litre transparent re-sealable bag</li>
        </ul>
      </div>

      <div class="tw-mb-5 tw-text-3.25">
        <div class="header">
          Batteries
        </div>
        <img class="tw-my-2.5" src="/assets/images/batteries.svg"/>
        All spare/loose lithium batteries, including power banks, must be carried in the cabin baggage only.
      </div>
    </div>
  </div>

  <div class="scoot-card lg:tw-col-span-3" [class.has-error]="hasError">
    <div *ngIf="hasError" class="tw-text-3.25 tw-text-red-600 tw-mb-0.75">
      Acknowledgement is required
    </div>
    <div>
      <input type="checkbox" id="acknowledge" [formControl]="acknowledgement" style="visibility:hidden; position:absolute;"/>
      <label for="acknowledge" class="tw-flex lg:tw-items-center">
        <div class="checkbox-icon"></div>
        <div class="tw-text-3.25">
          I have read and accept the restrictions on Dangerous Goods
        </div>
      </label>
    </div>
  </div>
</div>
