import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StringUnitLength } from 'luxon';
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { ApplicationStateService } from './application-state.service';
import { ItineraryService } from './itinerary.service';

export interface AlternativeEmail {
  email: string;
}
export interface PassengerQueue {
  passengerName: string;
  queueNumber: string;
}

@Injectable({
  providedIn: 'root'
})

export class EmailService {
  alternativeEmail: AlternativeEmail | null;
  constructor(
    private httpClient: HttpClient,
    private applicationStateService: ApplicationStateService,
    private itineraryService: ItineraryService
  ) {  }

  updateAlternativeEmail(alternativeEmail: AlternativeEmail){
    this.alternativeEmail = alternativeEmail;
  }

  sendSuccessEmail$(send: boolean): Observable<boolean>{
    
    if (!send) return of(false);
    
    let contactName: string;
    let contactEmail: string;
    let recordLocator: string;
    let checkedInPassengers: string[];
    
    this.applicationStateService.booking$.pipe(
      map(booking => {
        let passenger = booking.passengers[0];
        contactName = `${passenger.title} ${passenger.firstName} ${passenger.lastName}`;
        recordLocator = booking.recordLocator;
        checkedInPassengers = booking.passengers.map(passenger => this.encodeUnicode(`${passenger.title} ${passenger.firstName} ${passenger.lastName}`));
        contactEmail = this.alternativeEmail!.email
      }),
      switchMap(() => this.httpClient.post<boolean>('/Itinerary', {contactName : this.encodeUnicode(contactName), contactEmail: this.encodeUnicode(contactEmail), recordLocator : this.encodeUnicode(recordLocator), checkedInPassengers }))
    ).subscribe();
    return of(true);
  }

  reSendSuccessEmail$(): Observable<boolean>{
    let contactName: string;
    let contactEmail: string;
    let recordLocator: string;
    let checkedInPassengers: string[];
    
    this.applicationStateService.booking$.pipe(
      map(booking => {
        let firstPassenger = booking.passengers[0];
        contactName = `${firstPassenger.title} ${firstPassenger.firstName} ${firstPassenger.lastName}`;
        recordLocator = booking.recordLocator;
        checkedInPassengers = booking.passengers.filter(passenger => passenger.isCheckedIn).map(passenger => this.encodeUnicode(`${passenger.title} ${passenger.firstName} ${passenger.lastName}`));
        contactEmail = this.alternativeEmail!.email
      }),
      switchMap(() => this.httpClient.post<boolean>('/Itinerary', {contactName : this.encodeUnicode(contactName), contactEmail: this.encodeUnicode(contactEmail), recordLocator : this.encodeUnicode(recordLocator), checkedInPassengers }))
    ).subscribe();
    return of(true);

  }

  sendQueueEmail(): Observable<boolean> {
    let contactName: string;
    let contactEmail: string;
    let recordLocator: string;
    let passengersQueue: PassengerQueue[];
    
    this.applicationStateService.booking$.pipe(
      map(booking => {
        let passenger = booking.passengers[0];
        contactName = `${passenger.title} ${passenger.firstName} ${passenger.lastName}`;
        recordLocator = booking.recordLocator;
        const queuedPassengers = this.itineraryService.passengerItinerary.passengers.filter(p => !p.canCheckIn);
        passengersQueue = queuedPassengers.map(passenger => {
          return {
              passengerName: this.encodeUnicode(`${passenger.title} ${passenger.firstName} ${passenger.lastName}`),
              queueNumber: this.encodeUnicode(passenger.queue)
          } as unknown as PassengerQueue;
        });
        contactEmail = this.alternativeEmail!.email
      }),
      switchMap(() => this.httpClient.post<boolean>('/Queue/send', { 
        contactName: this.encodeUnicode(contactName), 
        contactEmail: this.encodeUnicode(contactEmail), recordLocator: this.encodeUnicode(recordLocator), passengersQueue: passengersQueue }))
    ).subscribe();
    return of(true);
  }

  encodeUnicode(str: string) {
    return btoa(unescape(encodeURIComponent(str)))
  }
}