import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'not-availabl-widget',
  templateUrl: './not-available-widget.component.html',
  styleUrls: ['./not-available-widget.component.scss']
})
export class NotAvailablePageComponent {
  constructor(private router: Router) {}
  
  ngOnInit() {
    // setTimeout(() => {
    //     this.router.navigate(['/']);
    // }, 5000);  //5s
}
}