
import { Component, Injectable } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Duration } from 'luxon';
import { Observable, of, switchMap } from 'rxjs';
import { CallToActionHandlerService } from '../call-to-action/call-to-action-handler.service';
import { Journey, Leg, Passenger, Segment } from '../services/application-state.service';
import { EmailService } from '../services/email.service';
import { ItineraryService } from '../services/itinerary.service';
import { PageLoaderService } from '../services/page-loader.service';
import { PassengerService } from '../services/passenger.service';
import { ResourceService, Station } from '../services/resource.service';
import { CallToActionConfig, CallToActionHandler } from './../call-to-action/call-to-action.component';
import { RouterService } from './../services/router.service';
import { QueueEmailDialogService } from './queue-email-dialog/queue-email-dialog.service';
import { QueueNoticeDialogService } from './queue-notice-dialog/queue-notice-dialog.service';

export interface PassengerItinerary {
  passengers: Passenger[],
  journey: Journey,
  contactEmailAddress: string
}

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-itinerary-widget',
  templateUrl: './itinerary-widget.component.html',
  styleUrls: ['./itinerary-widget.component.scss'],
})
export class ItineraryWidgetComponent extends CallToActionHandler {
  passengerItinerary: PassengerItinerary = {
    journey: {} as Journey,
    passengers: [],
    contactEmailAddress: ''
  };

  alternativeEmail = new UntypedFormControl('', { validators: [Validators.email, Validators.required], updateOn: 'blur' });
  isLoading = false;
  checkedIn = false;

  private stations: Station[];
  
  constructor(
    private router: RouterService,
    private itineraryService: ItineraryService,
    callToActionHandlerService: CallToActionHandlerService,
    private resourceService: ResourceService,
    private emailService: EmailService,
    private pageLoaderService: PageLoaderService,
    private queueNoticeDialogService: QueueNoticeDialogService,
    private passengerService: PassengerService,
    private queueEmailDialogService: QueueEmailDialogService
  ) {
    super();
    callToActionHandlerService.registerHandler(this);
    this.passengerItinerary = this.itineraryService.passengerItinerary;
    
    if (this.passengerItinerary.contactEmailAddress !== ''){
      this.alternativeEmail.setValue(this.passengerItinerary.contactEmailAddress);
    }
    
    this.itineraryService.proceedWithPartialCheckIn$.subscribe(() => {
      this.router.next$('passenger').subscribe();
    });

    this.queueEmailDialogService.redirectToLogin$.subscribe(() => {
      this.router.next$('').subscribe();
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.pageLoaderService.showPageLoader();
    this.resourceService.getStations$().pipe(
      switchMap(stations => this.stations = stations)
    ).subscribe();
    this.itineraryService.queueAssignmentCompletedSubject$.subscribe(() => {
      this.itineraryService.queueAssigned = true;
      this.pageLoaderService.hidePageLoader();
      this.isLoading = false;
      
      if (this.passengerItinerary.passengers.every(p => !p.canCheckIn)) {
        this.updateCallToActionConfig({ primaryText: 'Send confirmation email'} as CallToActionConfig);
      }

      if (this.passengerItinerary.passengers.every(p => p.isCheckedIn)) {
        this.updateCallToActionConfig({ primaryText: 'Re-send Email Confirmation'} as CallToActionConfig);
        this.checkedIn = true;
      }
    });
  }

  override onPrimaryClicked$(): Observable<boolean> {
    if (this.alternativeEmail.invalid){
      return of(false);
    } 
    this.emailService.updateAlternativeEmail({ email: this.alternativeEmail.value })

    if (this.itineraryService.passengerItinerary.passengers.every(p => !p.canCheckIn)){
      this.emailService.sendQueueEmail().subscribe();
      this.queueEmailDialogService.show();
      this.updateCallToActionConfig({ show: false } as CallToActionConfig);
      return of(false);
    }
    
    if (this.passengerItinerary.passengers.every(p => p.isCheckedIn)) {
      this.emailService.reSendSuccessEmail$().subscribe();
      return this.router.next$('confirmation');
    }

    if (this.passengerItinerary.passengers.some(p => !p.canCheckIn)) {
      const allowedToCheckInCount = this.passengerItinerary.passengers.filter(p => p.canCheckIn).length;
      this.queueNoticeDialogService.setPassengersAllowedForCheckIn(allowedToCheckInCount);
      this.queueNoticeDialogService.show();
      return of(false);
    }

    this.itineraryService.setPassengersForCheckIn();
    return this.router.next$('passenger');
    
  }

  getDepartureIcon(leg: Leg, segment: Segment): string {
      if (segment.legs.length == 1 || this.firstLeg(segment) == leg) return "icon-plane-taking-off";
      if (this.lastLeg(segment) == leg) return "icon-dot";
      return "icon-dot";
  }

  getArrivalIcon(leg: Leg, segment: Segment): string {
    if (segment.legs.length == 1) return "icon-location-pin";
    if (this.firstLeg(segment) == leg) return "icon-dot";
    if (this.lastLeg(segment) == leg) return "icon-location-pin";
    return "icon-dot";
  }

  private firstLeg(segment: Segment) {
    return segment.legs[0];
  }

  private lastLeg(segment: Segment) {
    if (segment.legs.length > 0){
      return segment.legs[segment.legs.length -1];
    }else {
      return null;
    }
  }

  GetFlightDuration(travelDuration: number): string {
    return Duration.fromMillis(travelDuration).toFormat(`h'h' m'm'`);
  };

  getStationName(stationCode: string){
    var station = this.stations.find(station => station.code == stationCode);
    return station?.name;
  };

  getAirportName(stationCode: string){
    var station = this.stations.find(station => station.code == stationCode);
    return station?.shortName;
  }

  displayWarningIcon(passenger: Passenger) : boolean {
    return !passenger.isCheckedIn 
        && (passenger.hasRestrictedSsr 
            || passenger.isBufferSeat 
            || (passenger.queueDisplay != "OK to check-in"
            && passenger.queueDisplay != "Checked in"))
        && !passenger.canCheckIn;
  }

  isQueueWithNoBuffer(passenger: Passenger) : boolean {
    return !passenger.isCheckedIn 
        && !passenger.isBufferSeat 
        && !passenger.hasRestrictedSsr
        && passenger.queueDisplay != "OK to check-in"
        && passenger.queueDisplay != "";
  }

  isCheckedIn(): boolean {
    if (this.checkedIn){
      this.updateCallToActionConfig({ primaryText: 'Continue', show: false } as CallToActionConfig);
    }
    return this.checkedIn;
  }
}
