import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, of, switchMap, tap } from 'rxjs';
import { CallToActionHandlerService } from '../call-to-action/call-to-action-handler.service';
import { CallToActionHandler } from '../call-to-action/call-to-action.component';
import { ApplicationStateService } from '../services/application-state.service';
import { CheckInService } from '../services/check-in.service';
import { EmailService } from '../services/email.service';
import { PageLoaderService } from '../services/page-loader.service';
import { RouterService } from '../services/router.service';

@Component({
  selector: 'app-safety-and-security',
  templateUrl: './safety-and-security-widget.component.html',
  styleUrls: ['./safety-and-security-widget.component.scss']
})
export class SafetyAndSecurityWidgetComponent extends CallToActionHandler {
  acknowledgement: FormControl;

  constructor(
    private router: RouterService,
    callToActionHandlerService: CallToActionHandlerService,
    private pageLoaderService: PageLoaderService,
    private checkInService: CheckInService,
    private applicationStateService: ApplicationStateService,
    private emailService: EmailService
  ) { 
    super();
    callToActionHandlerService.registerHandler(this);
    this.acknowledgement = new FormControl(false, Validators.requiredTrue);
  }

  get hasError() : boolean {
    return this.acknowledgement.dirty && this.acknowledgement.invalid;
  }

  override onPrimaryClicked$(): Observable<boolean> {
    this.acknowledgement.markAsDirty();
    if(!this.hasError) {
      this.pageLoaderService.showPageLoader();

      return this.checkInService
        .submit$()
        .pipe(
          tap(response => { if (!response) this.router.next$('error') }),
          switchMap(response => this.emailService.sendSuccessEmail$(response)),
          switchMap(() => this.router.next$('confirmation'))
        );
    }
    return of(false);
  }
}
