import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-mutliple-tag-input',
  templateUrl: './mutliple-tag-input.component.html',
  styleUrls: ['./mutliple-tag-input.component.scss']
})

export class MutlipleTagInputComponent {
  @ViewChild('tagInputField') inputField: ElementRef<HTMLInputElement>;
  @Input() inputTags: string[];
  @Input() validateNewTag: (args: string) => boolean;
  @Output() onChange = new EventEmitter<null>();
  isOnEditState: boolean = false;
  hasError: boolean = false;
  tagInput: string;
  constructor() { 
  }

  resetMode() {
    this.isOnEditState = false;
    this.tagInput = '';
  }

  setToEditState(){
    this.isOnEditState = true;
    setTimeout(() => this.inputField.nativeElement.focus(), 100);
  }

  addTag() {
    const skipValidation = typeof this.validateNewTag === 'undefined';
    if(this.tagInput) {
      if(skipValidation || this.validateNewTag(this.tagInput)) {
        this.inputTags.push(this.tagInput);
        this.hasError = false;
        this.onChange.emit();
      }
    }
    
    if(this.inputTags.length === 0) {
      this.hasError = true;
    }

    this.resetMode();
  }

  removeTag(index: number) {
    this.inputTags.splice(index, 1);
    this.onChange.emit();
    if(this.inputTags.length === 0) {
      this.hasError = true;
    }
  }
}
