import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-scoot-card',
  templateUrl: './scoot-card.component.html',
  styleUrls: ['./scoot-card.component.scss'],
})
export class ScootCardComponent {
  @Input() scootCardHeaderTemplate: TemplateRef<any>;
  @Input() scootCardBodyTemplate: TemplateRef<any>;
  @Input() scootCardFooterTemplate: TemplateRef<any>;
}
