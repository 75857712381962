import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageLoaderService {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject({} as boolean);
  
  constructor(
    private router: Router
  ) { 
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.isLoading$.next(false));
  }

  showPageLoader() {
    this.isLoading$.next(true);
  }

  hidePageLoader() {
    this.isLoading$.next(false);
  }
}
