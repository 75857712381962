<app-admin-menu-tab></app-admin-menu-tab>
<div class="tw-mb-12">
  <div class="tw-text-lg tw-font-bold tw-mt-5">Filters</div>
  <div class="lg:tw-flex">
    <app-range-date-picker class="tw-w-full" (startDate)="startDate=$event"
      (endDate)="endDate=$event"></app-range-date-picker>
    <div class="tw-flex lg:tw-justify-between tw-w-full">
      <div class="lg:tw-ml-[100px] tw-flex">
        <div>
          <label class="tw-text-0xl tw-m-0 tw-mb-0.75">
            Search
          </label>
          <input class="tw-text-3 lg:tw-text-1xl tw-border-b tw-border-b-scoot-black-100 tw-w-full tw-bg-transparent"
            type="text" [(ngModel)]="filterKeyword" />
        </div>
        <div class="tw-ml-[10px] tw-pt-[15px]">
          <button class="scoot-button scoot-button--primary" (click)="filterStandbyHistory()">Filter</button>
        </div>
      </div>
      <div class="tw-ml-[10px] tw-pt-[15px]">
        <button class="scoot-button scoot-button--primary" (click)="exportFile()">Export</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="filteredStandbyHistoryItemsSliced && filteredStandbyHistoryItemsSliced.length > 0">
  <div class="tw-grid tw-grid-cols-9 tw-mb-6 tw-font-bold tw-text-0.5 tw-px-[30px]">
    <div class="tw-justify-self-center" style="cursor: pointer;" (click)="toggleSorting('date')">
      Reg Date / Time {{ sortingField !== 'date' ? '' : sortingType === 'none' ? '' : sortingType ===
      'descending' ? '↓' : '↑'}}</div>
    
    <div class="tw-justify-self-center">PNR</div>
    <div class="tw-justify-self-center" style="cursor: pointer;" (click)="toggleSorting('priority')">
      Queue No. {{ sortingField !== 'priority' ? '' : sortingType === 'none' ? '' : sortingType === 'descending' ? '↓'
      : '↑'}}
    </div>
    <div class="tw-justify-self-center">First Name</div>
    <div class="tw-justify-self-center">Last Name</div>
    <div class="tw-justify-self-center">Flight Number</div>
    <div class="tw-justify-self-center">Dep Date/Time</div>
    <div class="tw-justify-self-center">Staff Airline</div>
    <div class="tw-justify-self-center">Ok To Check-In</div>

  </div>
  <div class="scoot-card">
    <div *ngFor="let item of filteredStandbyHistoryItems | paginate: { itemsPerPage: 20, currentPage: pageNumber }; let i = index;"
      class="tw-grid tw-grid-cols-9 tw-font-bold tw-text-1"
      [class.tw-pb-6]="i < 19" [class.tw-pt-6]="i > 0"
      [class.divider]="i < 19">
      <div class="tw-justify-self-center">{{item.createdDate | date: 'dd MMM yyy / HH:mm:ss.SSS'}}</div>
      <div class="tw-justify-self-center">{{item.recordLocator}}</div>
      <div class="tw-justify-self-center">{{item.order}}</div>
      <div class="tw-justify-self-center">{{item.firstName}}</div>
      <div class="tw-justify-self-center">{{item.lastName}}</div>
      <div class="tw-justify-self-center">{{item.flightNumber}}</div>
      <div class="tw-justify-self-center">{{item.flightDate | date: 'dd MMM yyyy / HH:mm'}}</div>      
      <div class="tw-justify-self-center">{{item.staffAirline}}</div>
      <div class="tw-justify-self-center">{{item.canCheckIn}}</div>
    </div>
  </div>
</div>
<div  class="tw-w-full tw-flex tw-justify-center tw-items-center tw-bg-scoot-yellow-100 tw-absolute tw-left-0 tw-mt-8 tw-h-[4.75rem] ">
  <pagination-controls previousLabel="&#11207;" nextLabel="&#11208;" (pageChange)="pageNumber = $event" style="width: 100%; text-align: center; background-color: rgb(255 233 0); color: black; font-size: 16pt;" 
  class="float-left"></pagination-controls> 
</div>


