import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { ApplicationStateService, Booking } from './application-state.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(
    private httpClient: HttpClient,
    private applicationStateService: ApplicationStateService
  ) {}

  retrieve$(bookingReference: string, departing: string): Observable<boolean> {
    return this.httpClient
      .post<Booking>('/booking/retrieval', { bookingReference, departing })
      .pipe(
        tap((response) => this.applicationStateService.updateBooking(response)),
        map(() => true)
      );
  }

  // retrieveState$(): Observable<boolean> {
  //   return this.httpClient.get<Booking>('/booking')
  //     .pipe(
  //       tap(response => this.applicationStateService.updateBooking(response)),
  //       map(() => true),
  //       catchError(errorResponse => {
  //         this.applicationStateService.updateApplicationError(errorResponse.error);
  //         return of(false);
  //       })
  //     );
  // }
}
