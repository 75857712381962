import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { FlightQueue, Queue } from './application-state.service';

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  generateQueue$(JourneyKey: string, PassengerKeys: string[], JourneyIndex: number): Observable<Queue[]> {
    return this.httpClient
      .post<Queue[]>('/queue', { JourneyKey, PassengerKeys, JourneyIndex })
      .pipe(shareReplay(1));
  }

  retrieveQueuesInFlights$(flightDesignators: string[]): Observable<FlightQueue[]> {
    var TimeZoneOffSet = (new Date().getTimezoneOffset() / 60) * -1;
    const response = this.httpClient.post<FlightQueue[]>('/queue/flight', { flightDesignators, TimeZoneOffSet });
    return response;
  }

  removeCheckInStatus(JourneyKey: string, PassengerKeys: string[]) : Observable<Boolean> {
    
    const response = this.httpClient.post<boolean>('/queue/remove', { JourneyKey, PassengerKeys });
    
    return response;
  }
}