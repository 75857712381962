<h1 class="tw-text-4 lg:tw-text-4xl tw-mb-5">{{title}}</h1>
<div *ngIf="canViewComponent">
  <div class="scoot-card tw-mb-[20px]">
    <div class="menu-tab selected tw-text-center lg:tw-hidden tw-cursor-pointer" (click)="showMenu = !showMenu">
      <span [hidden]="activeTab !== '/qmp/home'">Home</span>
      <span [hidden]="activeTab !== '/qmp/standby-history'">Standby History</span>
      <span [hidden]="activeTab !== '/qmp/setting'">Setting</span>
      <span class="arrow-down"></span>
    </div>
    <div class="lg:tw-flex lg:tw-justify-between tw-rounded-lg lg:tw-rounded-full tw-bg-scoot-black-10 tw-p-[5px] lg:tw-w-[445px]" [hidden]="isMobile && !showMenu">
      <a class="hover:tw-no-underline" routerLink="/qmp/home">
        <div class="menu-tab" [class.selected]="!isMobile && activeTab === '/qmp/home'">Home</div>
      </a>
      <a class="hover:tw-no-underline" routerLink="/qmp/standby-history">
        <div class="menu-tab" [class.selected]="!isMobile && activeTab === '/qmp/standby-history'">Standby History</div>
      </a>
      <a class="hover:tw-no-underline" routerLink="/qmp/setting">
        <div class="menu-tab" [class.selected]="!isMobile && activeTab === '/qmp/setting'">Setting</div>
      </a>
    </div>
  </div>
</div>