import { Component } from '@angular/core';

@Component({
  selector: 'app-error-widget',
  templateUrl: './error-widget.component.html',
  styleUrls: ['./error-widget.component.scss']
})
export class ErrorWidgetComponent {
  constructor() {}
}
