<h1 class="tw-text-4 lg:tw-text-4xl tw-mb-5">Staff web check-in successful!</h1>
<div class="tw-mb-1 tw-text-3 tw-font-bold">
    Get your boarding pass
</div>
<p class="lg:tw-w-3/5 tw-mb-1 tw-text-3">
  Kindly proceed to the airport counter with your travel documents to finalize your check-in and print your boarding pass. See you onboard!
</p>
<div class="tw-mt-12">
  <img src="/assets/images/big-plane.svg"/>
</div>
