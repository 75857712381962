import { Injectable } from '@angular/core';
import { ApplicationStateService, Journey, Passenger } from './application-state.service';
import { Country, Station, ResourceService } from '../services/resource.service';
import { QueueService } from './queue.service';
import { combineLatest, empty, map, Observable, of, ReplaySubject, Subject, switchMap, take, tap } from 'rxjs';
import { BookingService } from './booking.service';


export interface PassengerItinerary {
  departureStation: string,
  arrivalStation: string,
  passengers: Passenger[],
  journey: Journey,
  contactEmailAddress: string
}


@Injectable({
  providedIn: 'root'
})
export class ItineraryService {
  queueAssignmentCompletedSubject$: ReplaySubject<void>;
  proceedWithPartialCheckIn$: ReplaySubject<void>;
  passengerItinerary$: Subject<PassengerItinerary>;
  passengerItinerary: PassengerItinerary = {
    departureStation: '',
    arrivalStation:'',
    passengers: [],
    journey: {} as Journey,
    contactEmailAddress: ''
  };

  queueAssigned: boolean;

  constructor(
    private applicationStateService: ApplicationStateService,
    private queueService: QueueService,
  ) {
    this.queueAssigned = false;
    this.queueAssignmentCompletedSubject$ = new ReplaySubject<void>();
    this.proceedWithPartialCheckIn$ = new ReplaySubject<void>();
    this.passengerItinerary$ = new Subject<PassengerItinerary>();
    this.applicationStateService.booking$.pipe(
      tap(booking => {
        this.passengerItinerary.passengers = booking.passengers;
        this.passengerItinerary.journey = booking.journey;
        this.passengerItinerary.contactEmailAddress = booking.contactEmailAddress;
      }),
      switchMap(() => {
        const passengerKeys = this.passengerItinerary.passengers
        .filter((pax) => !pax.isCheckedIn)
        .map((pax) => pax.key);
        const journeyIndex = this.passengerItinerary.journey.index;
        if (passengerKeys.length === 0) return of(null);
        if (journeyIndex < 0 || journeyIndex == null) return of(null);
        return this.queueService.generateQueue$(this.passengerItinerary.journey.key, passengerKeys, journeyIndex);
      }),
      map(queueResponse => {
        this.passengerItinerary.passengers.forEach(passenger => {
          if (queueResponse !== null) {
            const passengerQueue = queueResponse.find(q => q.passengerKey == passenger.key);
            console.log('passenger', passengerQueue);

            if (passengerQueue !== null){
              passenger.queue = this.formatQueueNumber(passengerQueue!.queueNumber);
              passenger.hasRestrictedSsr = passengerQueue!.hasRestrictedSsr;
              passenger.isBufferSeat = passengerQueue!.isBufferSeat;
              passenger.staffInQueue = passengerQueue!.staffInQueue;
              passenger.canCheckIn = passengerQueue!.canCheckIn;
              passenger.queueDisplay = passenger.canCheckIn ? "OK to check-in" : passenger.queue;
              passenger.systemQueue = passengerQueue!.systemQueueNumber;
            }
          }
          if(passenger.isCheckedIn) {
            passenger.queueDisplay = "Checked in";
          }
        })
        this.queueAssignmentCompletedSubject$.next();
      })
    ).subscribe();
  }

  formatQueueNumber(queue: string|null) : string {
    if (queue !== "" && queue !== null) {
      console.log('returning with q')
      return `Q: ${queue}`;
    }
    return "";
  }

  removeCheckInStatus(passengerKeys: string[]) {
    this.queueService.removeCheckInStatus(this.passengerItinerary.journey.key, passengerKeys).subscribe();
  }

  setPassengersForCheckIn() {
    this.passengerItinerary$.next(this.passengerItinerary);
  }
}
