<div class="tw-grid tw-grid-cols-10 tw-border tw-border-scoot-black-100 tw-font-bold">
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-4 tw-border tw-border-r-scoot-black-100">Priority Code</div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-4 tw-border tw-border-r-scoot-black-100">Queue Code</div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-1"></div>
</div>
<div *ngFor="let item of queueAssignmentItems; index as i;" class="tw-grid tw-grid-cols-10 tw-border tw-border-scoot-black-100 tw-border-t-0">
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-4 tw-border tw-border-r-scoot-black-100">
    <input type="text" [(ngModel)]="item.priorityCode" class="tw-w-24" (change)="onChange.emit()"/>
  </div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-4 tw-border tw-border-r-scoot-black-100">
    <input type="text" [(ngModel)]="item.queueCode" class="tw-w-24" (change)="onChange.emit()"/>
  </div>
  <div class="tw-px-[10px] tw-py-[5px] tw-col-span-1"><img src="/assets/images/tag-delete.svg" (click)="RemoveItem(i)" class="tw-cursor-pointer"/></div>
</div>
<div class="tw-flex tw-justify-end tw-mt-[20px]">
  <button type="button" class="scoot-button scoot-button--primary" (click)="AddItem()">Add Row</button>
</div>