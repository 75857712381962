import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent {
  @Input() numberInput: number | null;
  @Output() onChange = new EventEmitter<number>();
  focusedOut: boolean;
  constructor() { }

  setFocusOut(value: boolean) {
    this.focusedOut = value;
    this.onChange.emit(this.numberInput!);
  }

  updateInput(element: Event){
    this.numberInput = Number((element.target as HTMLInputElement).value);
  }
}
