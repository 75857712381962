import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { ApplicationStateService } from './application-state.service';
import { QueueService } from './queue.service';

@Injectable({
  providedIn: 'root'
})

export class CheckInService {
  constructor(
    private httpClient: HttpClient,
    private applicationStateService: ApplicationStateService,
    private queueService: QueueService) { }

  submit$(): Observable<boolean> {
    return this.applicationStateService.booking$.pipe(
      switchMap((booking) => 
        this.queueService.generateQueue$(booking.journey.key, booking.passengers.map(p => p.key), booking.journey.index)
          .pipe(
            map(queues => queues.filter(q => q.canCheckIn)),
            map(canCheckInQueues => ({ 'journeyKey': booking.journey.key, 'passengerKeys': canCheckInQueues.map(p => p.passengerKey)}))
          )
      ),
      switchMap(checkInRequest => 
        this.httpClient.post<boolean>('/check-in', checkInRequest)),
      map(_ => true),
      map((response) => response));
  };
}
