<div class="tw-text-1xl tw-mx-1.5 tw-mb-5 tw-mt-10">
    A confirmation email of flight status will be sent to your booking email address. Alternatively, you may update a different email address below to receive the confirmation email.
</div>
<div class="email-card ">
    <div class="tw-text-0xl tw-mt-7.5 tw-ml-7.5 tw-mb-7.5 tw-mr-7.5">
        <div class="tw-text-0xl tw-mb-0.75 tw-font-bold">
            Email Address
        </div>
        <input type="text" class="tw-border-b tw-border-scoot-black-100 tw-w-full tw-h-7" [formControl]='alternativeEmailControl' placeholder="Enter your email address"/>
        <div *ngIf="alternativeEmailControl.invalid" class="tw-text-0xl tw-m-0 tw-mb-0.75 tw-text-red-600">{{errorMessage}}</div>
    </div>
</div>
