import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss']
})
export class RadioInputComponent {
  @Input() radioInputValue: boolean;
  @Output() outputEvent = new EventEmitter<boolean>();;
  constructor() {}

  setValue(value: boolean) {
    this.radioInputValue = value;
    this.outputEvent.emit(value);
  }
}
