import { Component, OnInit } from '@angular/core';
import { QueueEmailDialogService } from './queue-email-dialog.service';

@Component({
  selector: 'app-queue-email-dialog',
  templateUrl: './queue-email-dialog.component.html',
  styleUrls: ['./queue-email-dialog.component.scss']
})
export class QueueEmailDialogComponent implements OnInit {
  show: boolean = true;
  constructor(private queueEmailDialogService: QueueEmailDialogService) { }

  ngOnInit(): void {
    this.queueEmailDialogService.displayDialog.subscribe(value => this.show = value);
  }

  process() : void {
    this.queueEmailDialogService.redirectToLogin$.next();
  }

}
