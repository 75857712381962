import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Flight } from './application-state.service';

@Injectable({
  providedIn: 'root'
})
export class FlightService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  retrieveDepartingFlights$(DepartureStation: string): Observable<Flight[]> {
    var TimeZoneOffSet = (new Date().getTimezoneOffset() / 60) * -1;
    return this.httpClient.post<Flight[]>('/flight/getFlights', { DepartureStation,  TimeZoneOffSet });
  }
}
