import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { BookingService } from './booking.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private bookingService: BookingService
  ) {
    this.loginForm = this.formBuilder.group({
      bookingReference: ['', [Validators.required, Validators.maxLength(6)]],
      departing: [undefined, Validators.required]
    });
  }

  login$(bookingReference: string, departing: string): Observable<boolean> {
    return this.bookingService.retrieve$(bookingReference, departing);
  }
}
