import { Component, OnInit } from '@angular/core';
import { QueueService } from '../services/queue.service';
import { PageLoaderService } from '../services/page-loader.service';
export interface StandbyHistoryItem {
  referenceCode: string,
  createdDate: Date,
  recordLocator: string,
  order: string,
  firstName: string,
  lastName: string,
  flightNumber: string,
  staffAirline: string,
  flightDesignator: string,
  cabinClass: string,
  equipmentType: string,
  flightDate: Date,
  canCheckIn: boolean
}

const pageIncrement = 20;

type sorting = 'descending' | 'ascending' | 'none';
type sortingField = 'priority' | 'date' | '';

@Component({
  selector: 'app-admin-standby-history-widget',
  templateUrl: './admin-standby-history-widget.component.html',
  styleUrls: ['./admin-standby-history-widget.component.scss']
})
export class AdminStandbyHistoryWidgetComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  filterKeyword: string;
  standbyHistoryItems: StandbyHistoryItem[];
  filteredStandbyHistoryItems: StandbyHistoryItem[];
  filteredStandbyHistoryItemsSliced: StandbyHistoryItem[];
  pages: number[];
  activePage: number;
  sortingType: sorting;
  sortingField: sortingField;
  pageNumber: number = 1;
  constructor(
    private queueService: QueueService,
    private pageLoaderService: PageLoaderService,
  ) {
    this.queueService.retrieveQueuesInFlights$(['TR'])
      .subscribe((queues) => {
        this.standbyHistoryItems = queues.map(queue => {
          const createdDate = new Date(queue.createdDateTime);

          let flightDate;
          if (queue.flightDesignator) {
            const stringDate = queue.flightDesignator.split(" ")[1]
            const yr = parseInt(stringDate.substring(0, 4));
            const mon = parseInt(stringDate.substring(4, 6));
            const dt = parseInt(stringDate.substring(6, 8));
            const [hoursB, minutesB] = queue.flightTimes.split("/")[0].trim().split(':').map(Number);

            flightDate = new Date(yr, mon, dt);
            flightDate.setMonth(flightDate.getMonth() - 1);
            flightDate.setHours(hoursB);
            flightDate.setMinutes(minutesB);
          }

          return {
            canCheckIn: queue.canCheckIn,
            referenceCode: queue.referenceCode,
            createdDate: createdDate,
            recordLocator: queue.referenceCode?.substring(0, 6),
            order: queue.queueNumber ?? "N/A",
            firstName: queue.firstName,
            lastName: queue.lastName,
            flightNumber: queue.flightDesignator.split(" ")[0],
            staffAirline: queue.queueCode?.substring(0, 2) ?? "N/A",
            flightDesignator: queue.flightDesignator,
            cabinClass: queue.cabinClass,
            equipmentType: queue.equipmentType,
            flightDate
          } as StandbyHistoryItem;
        });
        console.log(this.standbyHistoryItems)
        this.filterStandbyHistory();
        this.pageLoaderService.hidePageLoader();
      });

    this.sortingType = 'none';
  }
  ngOnInit(): void {
    this.pageLoaderService.showPageLoader();
  }

  filterStandbyHistory() {
    this.filteredStandbyHistoryItems =
      this.standbyHistoryItems
        .filter(item => this.withinDateFilter(item.createdDate))
        .filter(item =>
          this.containsKeyword(item.firstName) ||
          this.containsKeyword(item.lastName) ||
          this.containsKeyword(item.recordLocator) ||
          this.containsKeyword(item.flightNumber)
        );
    this.filteredStandbyHistoryItems.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
    this.activePage = 1;
    this.pages = Array
      .from(Array(Math.ceil(this.filteredStandbyHistoryItems.length / pageIncrement)).keys())
      .map(x => x + 1);
    this.updateFilteredStandbyHistoryItemsSliced();
    this.sortingType = 'none';
  }

  switchToPage(pageNumber: number) {
    if (pageNumber > this.pages.length || pageNumber < 1)
      return;

    this.activePage = pageNumber;
    this.updateFilteredStandbyHistoryItemsSliced();
  }

  toggleSorting(sortingField: sortingField) {
    if (sortingField !== this.sortingField) {
      this.sortingType = 'none';
    }

    this.sortingField = sortingField;

    if (this.sortingType === 'none') {
      this.sortingType = 'descending';
      if (sortingField === 'priority') {
        this.filteredStandbyHistoryItems =
          this.filteredStandbyHistoryItems.sort((a, b) => +a.order - +b.order);
      }
      else {
        this.filteredStandbyHistoryItems =
          this.filteredStandbyHistoryItems.sort(
            (a, b) => +(Date.parse(a.createdDate.toISOString())) - +(Date.parse(b.createdDate.toISOString())) || +a.order - +b.order);
      }
    }
    else if (this.sortingType === 'descending') {
      this.sortingType = 'ascending';

      if (sortingField === 'priority') {
        this.filteredStandbyHistoryItems =
          this.filteredStandbyHistoryItems.sort((a, b) => +b.order - +a.order);
      }
      else {
        this.filteredStandbyHistoryItems =
          this.filteredStandbyHistoryItems.sort(
            (a, b) => +(Date.parse(b.createdDate.toISOString())) - +(Date.parse(a.createdDate.toISOString())) || +b.order - +a.order);
      }
    }
    else {
      this.sortingType = 'none';
      this.filteredStandbyHistoryItems =
        this.standbyHistoryItems
          .filter(item => this.withinDateFilter(item.createdDate))
          .filter(item =>
            this.containsKeyword(item.firstName) ||
            this.containsKeyword(item.lastName) ||
            this.containsKeyword(item.recordLocator) ||
            this.containsKeyword(item.flightNumber)
          );
    }

    this.updateFilteredStandbyHistoryItemsSliced();
  }

  private updateFilteredStandbyHistoryItemsSliced() {
    const itemIndex = (this.activePage - 1) * pageIncrement;
    this.filteredStandbyHistoryItemsSliced = this.filteredStandbyHistoryItems.slice(itemIndex, itemIndex + pageIncrement);
  }

  private containsKeyword(field: string): boolean {
    if (!this.filterKeyword) {
      return true;
    }

    return this.filterKeyword.length > 0 && field?.toLowerCase().includes(this.filterKeyword?.toLowerCase());
  }

  private withinDateFilter(date: Date): boolean {
    if (!this.startDate || !this.endDate) {
      return true;
    }

    const parsedDate = new Date(date);
    return this.startDate <= parsedDate && this.endDate >= parsedDate;
  }

  exportFile() {
    let csvData = this.convertStandbyHistoryItemsToCsv();
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "report.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertStandbyHistoryItemsToCsv() {
    const headerList = [
      'createdDate',
      'recordLocator',
      'order',
      'firstName',
      'lastName',
      'flightNumber',
      'flightDate',
      'staffAirline',
      'canCheckIn'
    ];
    let array =
      typeof this.filteredStandbyHistoryItems != 'object' ?
        JSON.parse(this.filteredStandbyHistoryItems) :
        this.filteredStandbyHistoryItems;

    let str = '';
    let row = headerList.join(',');

    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < this.filteredStandbyHistoryItems.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        line += array[i][head];
        if (index != (headerList.length - 1).toString()) {
          line += ',';
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
}
