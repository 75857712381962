<app-admin-menu-tab></app-admin-menu-tab>
<div>
  <div class="tw-flex-grow-1 tw-shrink-0 tw-w-full lg:tw-w-[29%]">
    <div class="tw-text-lg tw-font-bold tw-mt-5">Departure Station:</div>
    <div class="tw-text-1xl">
      <ng-container *ngIf="isAdmin(); else stationReadOnlyTemplate">
        <ng-select
          class="scoot-dropdown"
          id="country-dropdown-departure-station"
          [clearable]="false"
          [items]="stationsItem"
          bindLabel="displayName"
          bindValue="codeAndTimeZone"
          [(ngModel)]="SelectedStation"
          (change)="updateFlights()">
        </ng-select>
      </ng-container>
      <ng-template #stationReadOnlyTemplate>
        <p class="selected-station-name">{{ SelectedStationName }}</p>
      </ng-template>

    </div>
  </div>

  <div class="tw-mt-10 tw-mb-5"><b>Departure Flights</b></div>
  <div class="tw-hidden lg:tw-flex lg:tw-visible lg:tw-font-bold lg:tw-text-0.9 lg:tw-mb-3">
    <div class="tw-ml-5">Flight No.</div>
    <div class="tw-ml-[151px]">Depart Date</div>
    <div class="tw-ml-[134px]">Depart Time</div>
    <div class="tw-ml-[131px]">Open for check-in</div>
    <div class="tw-ml-[98px]">Staff In Queue</div>
  </div>

  <ngb-accordion [closeOthers]="true" [destroyOnHide]="false">
    <ngb-panel id="flights-accordion-{{ i + 1 }}" *ngFor="let flight of flights; let i = index">
      <ng-template ngbPanelTitle>
        <div class="tw-grid tw-grid-flow-row tw-grid-cols-2 lg:tw-grid-cols-5">
          <div class="tw-w-36 lg:tw-flex lg:tw-mr-[62.74px]">
            <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">Flight No.</p>
            <p class="tw-font-bold tw-text-3.75 lg:tw-mr-[3px]">{{ flight.number }}</p>
            <p class="tw-font-bold tw-text-3.75">({{ flight.departureStation }}-{{flight.arrivalStation}})</p>
          </div>
          <div class="lg:tw-mr-[72px]">
            <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">Depart Date</p>
            <p class="tw-font-bold tw-text-3.75 tw-w-24 lg:tw-w-auto">{{ flight.departureDate | date : 'dd MMM yyyy, EEE' }}</p>
          </div>
          <div class="tw-mt-2.75 tw-w-[85px] lg:tw-mr-[142px] lg:tw-mt-0">
            <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">Depart Time</p>
            <p class="tw-font-bold tw-text-3.75">{{ flight.departureDate | date : 'HH:mm' }}</p>
          </div>
          <div class="lg:tw-mr-[168px]">
            <p class="tw-text-3.25 tw-leading-7 tw-w-[117px] tw-mt-2.5 lg:tw-hidden">Open for check-in</p>
            <p class="tw-font-bold tw-text-3.75">{{flight.openForCheckIn}}</p>
          </div>
          <div class="tw-mt-2.75 tw-col-span-2 lg:tw-col-auto lg:tw-mt-0">
            <p class="tw-text-3.25 tw-leading-7 lg:tw-hidden">Staff In Queue</p>
            <div class="tw-flex">
              <div *ngIf="flight.staffInBusinessClassQueue === 0 && flight.staffInEconomyClassQueue === 0"
                    class="tw-font-bold tw-mt-3 lg:tw-mt-0">-</div>
              <div *ngIf="flight.staffInBusinessClassQueue > 0" class="tw-font-bold tw-text-3 tw-rounded-2.5xl tw-bg-scoot-yellow-100 tw-py-1 tw-px-3 tw-mr-4">W ({{flight.staffInBusinessClassQueue}})</div>
              <div *ngIf="flight.staffInEconomyClassQueue > 0" class="tw-font-bold tw-text-3 tw-rounded-2.5xl tw-bg-scoot-blue-10 tw-text-white tw-py-1 tw-px-3">Y ({{flight.staffInEconomyClassQueue}})</div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div *ngIf="flight.staffQueues.length > 0">
          <app-admin-queue [StaffTravelQueues]="flight.staffQueues"></app-admin-queue>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
