<div id="CallToAction" *ngIf="this.config.show">
  <div class="tw-mx-3.75 lg:tw-mx-37.5 tw-flex tw-flex-row tw-justify-end">
    <button
      *ngIf="this.config.secondaryButton"
      class="scoot-button scoot-button--secondary tw-mr-2.5"
      [disabled]="this.config.disabled"
      [ngStyle]="{ 'opacity': this.config.disabled ? '0.5' : '1' }"
      (click)="secondaryClicked()"
    >
      {{ this.config.secondaryText }}
    </button>
    <button
  *ngIf="this.config.primaryButton"
  class="scoot-button scoot-button--primary"
  [disabled]="this.config.disabled"
  [ngStyle]="{ 'opacity': this.config.disabled ? '0.5' : '1' }"
  (click)="primaryClicked()"
>
  {{ this.config.primaryText }}
</button>

  </div>
</div>
