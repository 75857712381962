<div #rangeDatePicker>
  <div class="tw-flex tw-w-full tw-gap-2">
    <div class="tw-w-full">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">
        Start Date
      </label>
      <input 
        class="tw-text-3 lg:tw-text-1xl tw-border-b tw-border-b-scoot-black-100 tw-w-full tw-bg-transparent tw-pl-1" 
        type="text" 
        [(ngModel)]="startDateString" 
        placeholder="Select Date"
        (focus)="showDatePicker = true"
        (blur)="clearStartDateWhenEmpty()"/>
    </div>
    <div class="tw-w-full">
      <label class="tw-text-0xl tw-m-0 tw-mb-0.75">
        End Date
      </label>
      <input 
        class="tw-text-3 lg:tw-text-1xl tw-border-b tw-border-b-scoot-black-100 tw-w-full tw-bg-transparent tw-pl-1" 
        type="text" 
        [(ngModel)]="endDateString" 
        (focus)="showDatePicker = true"
        placeholder="Select Date"
        (blur)="clearEndDateWhenEmpty()"/>
    </div>
  </div>
  <div class="date-picker tw-relative" [hidden]="!showDatePicker">
    <ngb-datepicker
      [displayMonths]="isMobile ? 1 : 2" 
      [navigation]="'arrows'"
      [dayTemplate]="dayTemplate" 
      [footerTemplate]="footerTemplate" 
      [maxDate]="maxDate"
      [firstDayOfWeek]="0"
      [weekdays]="translationWidth"
      (dateSelect)="onDateSelection($event)"
      [startDate]="initialDate"
      outsideDays="hidden"
      class="tw-w-full tw-absolute tw-left-0">
    </ngb-datepicker>
    <ng-template #dayTemplate let-date let-disabled="disabled">
      <div style="width: 50%; height: 28px;"
        [class.range-connector]="showLeftConnector(date)"></div>
      <div style="width: 50%; height: 28px;"
        [class.range-connector]="showRightDateRangeConnector(date)"></div>
      <div class="tw-h-[28px] tw-w-[28px] tw-absolute tw-flex tw-items-center tw-justify-center tw-z-50" 
        [class.selected]="isSelected(date) || isHovered(date)"
        [class.disabled]="disabled"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        <span>{{ date.day }}</span>
      </div>
      <div class="tw-h-[28px] tw-w-[28px] tw-absolute tw-flex tw-items-center tw-justify-center tw-z-10"
        [class.weekend]="showWeekEnd(date)"></div>
    </ng-template>
    <ng-template #footerTemplate>
      <hr>
      <button class="scoot-button scoot-button--primary tw-float-right tw-m-[10px]" (click)="showDatePicker = false">Done</button>
    </ng-template>
  </div>
</div>
