<div 
  class="tw-border-x-0 tw-border-t-0 tw-border-b-black tw-border-[1px] tw-flex tw-flex-wrap tw-pb-[6px]" 
  [ngClass]="{'tw-border-b-scoot-red-error' : hasError}">
  <div *ngFor="let tag of inputTags; index as i;" class="tw-flex tw-bg-scoot-black-10 tw-rounded-[5px] tw-px-[5px] tw-py-[3px] tw-mt-[5px] tw-mr-[10px]">
    <span>{{tag}}</span>
    <img src="/assets/images/tag-delete.svg" (click)="removeTag(i)" class="tw-cursor-pointer"/>
  </div>
  <img src="/assets/images/tag-add.svg" *ngIf="!isOnEditState" (click)="setToEditState()" class="tw-cursor-pointer"/>
  <input [hidden]="!isOnEditState" #tagInputField type="text" (keydown.enter)="addTag()" (blur)="addTag()" [(ngModel)]="tagInput" placeholder="type here.."/>
</div>
<div *ngIf="hasError" class="tw-text-scoot-red-error">Cannot leave this field empty</div>