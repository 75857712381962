import { formatDate } from '@angular/common';
import { Component, Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup
} from '@angular/forms';
import { Observable, of, switchMap } from 'rxjs';
import { CallToActionHandlerService } from '../call-to-action/call-to-action-handler.service';
import { PageLoaderService } from '../services/page-loader.service';
import { PassengerService } from '../services/passenger.service';
import { Country, ResourceService } from '../services/resource.service';
import { CallToActionHandler } from './../call-to-action/call-to-action.component';
import { RouterService } from './../services/router.service';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-passenger-widget',
  templateUrl: './passenger-widget.component.html',
  styleUrls: ['./passenger-widget.component.scss'],
})
export class PassengerWidgetComponent extends CallToActionHandler {
  passengersFormGroup: FormGroup;
  days: { value: number; label: string }[];
  months: { value: number; label: string }[];
  years: number[];
  countries: Country[] = [];

  constructor(
    private router: RouterService,
    private passengerService: PassengerService,
    private resourceService: ResourceService,
    private pageLoaderService: PageLoaderService,
    callToActionHandlerService: CallToActionHandlerService
  ) {
    super();
    callToActionHandlerService.registerHandler(this);
    this.initDate();
    this.passengersFormGroup = this.passengerService.passengersFormGroup;
    this.resourceService.getCountries$().subscribe((countries) => (this.countries = countries));
  }

  change(event: any) {
    console.log('change event triggered', event, this.passengersFormGroup);
  }

  get passengerArray(): FormArray {
    return <FormArray>this.passengersFormGroup.get('passengers');
  }

  isInvalid(control: AbstractControl): boolean {
    return !control.valid && (control.dirty || control.touched);
  }

  passengerInputValid(
    index: number,
    errorKey: string,
    ...controlNames: string[]
  ): boolean {
    const formGroup = <FormGroup>this.passengerArray.at(index);
    const controls = controlNames?.map((name) => formGroup?.controls[name]);
    const hasErrors = controls.every((control) => this.isInvalid(control));
    let isValid = true;
    if (hasErrors) {
      const hasError = controls.some(
        (control) => control?.errors?.[errorKey] ?? false
      );
      isValid = !hasError;
    }
    return isValid;
  }

  passengerFormValid(index: number, errorKey: string): boolean {
    const formGroup = <FormGroup>this.passengerArray.at(index);
    const hasErrors = !formGroup.valid && (formGroup.dirty || formGroup.touched);
    let isValid = true;
    if (hasErrors) {
      const hasError = !!formGroup.errors && formGroup.errors[errorKey];
      isValid = !hasError;
    }
    return isValid;
  }

  get acknowledgementInvalid(): boolean {
    const acknowledgement = this.passengersFormGroup.get('acknowledgement')!;
    return acknowledgement.dirty && acknowledgement.invalid;
  }

  override onSecondaryClicked$(): Observable<boolean> {
    return this.router.previous$();
  }

  override onPrimaryClicked$(): Observable<boolean> {
    this.passengersFormGroup.markAllAsTouched();
    this.passengersFormGroup.markAsDirty();
    this.passengersFormGroup.get('acknowledgement')!.markAsDirty();
    const isValid = this.passengersFormGroup.valid;
    
    if (isValid) {
      this.pageLoaderService.showPageLoader();
      return this.passengerService
        .submit$()
        .pipe(switchMap(() => this.router.next$('safety-and-security')));
    }

    return of(isValid);
    
  }

  private initDate() {
    this.days = [...Array.from({ length: 31 }, (_, i) => i + 1)].map((n) => ({
      label: String(n).padStart(2, '0'),
      value: n,
    }));
    this.months = [...Array.from({ length: 12 }, (_, i) => i + 1)].map((n) => ({
      label: formatDate(new Date(2022, n - 1, 1), 'MMM', 'en-SG'),
      value: n,
    }));
    this.years = [
      ...Array.from({ length: 11 }, (_, i) => i + new Date().getFullYear()),
    ];
  }
}
