import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs';
import { AdminSettingHandlerService } from '../admin-setting-widget/admin-setting-handler.service';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-admin-menu-tab',
  templateUrl: './admin-menu-tab.component.html',
  styleUrls: ['./admin-menu-tab.component.scss']
})
export class AdminMenuTabComponent {
  activeTab = '';
  title = 'Queue Management Portal (Staff travel)';
  canViewComponent = false;
  isMobile = window.innerWidth < 740;
  showMenu = this.isMobile ? false : true;

  constructor(
    router: Router, 
    adminSettingService: AdminSettingHandlerService,
    accountService: AccountService) { 
    this.activeTab = router.url;
    adminSettingService.retrieve$().pipe(
      switchMap((settings) => {
        return accountService.getAccountInfo$().pipe(
          map((account) => { 
            return { accountRoleCodes: account?.roleCodes, adminRoleCodes: settings.adminRole };
          })
        )
      })
    )    
    .subscribe(({accountRoleCodes, adminRoleCodes}) => {
      const adminRole = accountRoleCodes?.find(role => adminRoleCodes.includes(role));
      this.canViewComponent = adminRole ? true : false;
      this.title = adminRole ? 'Scoot Admin' : 'Queue Management Portal (Staff travel)'
    });
  }
}
