import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-widget',
  templateUrl: './admin-widget.component.html',
  styleUrls: ['./admin-widget.component.scss']
})
export class AdminWidgetComponent {

  constructor() { }

}
