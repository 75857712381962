import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { CallToActionHandler } from './call-to-action.component';

@Injectable({
  providedIn: 'root',
})
export class CallToActionHandlerService {
  private _invokeSubscription = new BehaviorSubject<void>(undefined);
  private _callToActionHander: CallToActionHandler;

  registerHandler(callToAction: CallToActionHandler): void{
    this._callToActionHander = callToAction;
    this._invokeSubscription.next();
  }

  get currentCallToActionHandler(): CallToActionHandler {
    return this._callToActionHander;
  }

  invokeSubscription$() : Observable<void> {
    return this._invokeSubscription;
  }
}