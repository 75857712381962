import {CheckInTimetable, Country, StationLoginViewModel} from './../services/resource.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { PageLoaderService } from '../services/page-loader.service';
import { ResourceService, Station } from '../services/resource.service';
import {forkJoin, switchMap, tap} from "rxjs";

@Component({
  selector: 'app-login-widget',
  templateUrl: './login-widget.component.html',
  styleUrls: ['./login-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginWidgetComponent implements OnInit {
  loginForm: FormGroup;
  stations: Station[] = [];
  countries: Country[] =[];
  stationViews: StationLoginViewModel[] = [];

  timetable: CheckInTimetable | null = null;
  errorMessage: string | null = null;

  constructor(
    private router: Router,
    private resourceService: ResourceService,
    private loginService: LoginService,
    private pageLoaderService: PageLoaderService
  ) {

    this.resourceService.getStations$().pipe(
      tap(stations =>{
        this.stations = stations;
      }),
      switchMap(stations =>
        this.resourceService.getCountries$()
      ),
      tap(countries => {
        this.stationViews = this.stations.map(station =>{
          return {
            ...station,
            country: countries.find(x=>x.code === station.countryCode)
          }
        })
      })
    ).subscribe(()=> this.pageLoaderService.hidePageLoader());

    this.resourceService.getCheckInTimetable$().subscribe((timetable) => {
      this.timetable = timetable;
    });
    this.loginForm = this.loginService.loginForm;
  }

  stationViewsSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1
      ||item.country?.name.toLocaleLowerCase().indexOf(term) > -1
      ||item.code.toLocaleLowerCase().indexOf(term) > -1;
  }

  ngOnInit(): void {
    this.pageLoaderService.showPageLoader();
    if (this.stations.length > 0) {
      this.pageLoaderService.hidePageLoader();
    }
  }

  login() {
    this.loginForm.markAllAsTouched();
    this.loginForm.markAsDirty();
    if (this.loginForm.valid) {
      this.pageLoaderService.showPageLoader();
      this.loginService
        .login$(
          this.loginForm.value.bookingReference,
          this.loginForm.value.departing
        )
        .subscribe({
          next: (r) => this.router.navigate(['itinerary']),
          error: (e) => {
            this.errorMessage = e.error;
            this.pageLoaderService.hidePageLoader();
          },
        });
    } else {
      this.errorMessage =
        'Please ensure your Booking Reference (PNR) and Departing station are entered correctly.';
    }
  }

  isInvalid(controlName: string): boolean {
    const control = this.loginForm.get(controlName)!;
    return !control.valid && (control.dirty || control.touched);
  }
}
