<div class="card">
  <div class="card-body">
    <h2>Health Declaration</h2>
    <hr />
    <div>Due to the travel restrictions in the country you are visiting, you are required to complete this health declaration form.</div>
    <div>Passengers who do not complete this will be denied from checkin.</div>
    <hr />
    <form [formGroup]="healthDeclarationForm" (ngSubmit)="declare()" novalidate>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" role="presentation" *ngFor="let form of passengerArray.controls; let i = index;">
          <a class="nav-link {{ i == 0 ? 'active' : '' }}" data-toggle="tab" href="#tabpanel{{ i }}" role="tab"> {{ form.get('firstName')?.value }}</a>
        </li>
      </ul>
      <div class="tab-content" formArrayName="passengers">
        <div *ngFor="let form of passengerArray.controls; let i = index;"
          class="tab-pane fade show {{ i == 0 ? 'active' : '' }}" id="tabpanel{{ i }}" role="tabpanel" [formGroupName]="i">
          <br/>
          <div class="row" [class.has-no-selection]="isInvalid(form, 'declaration1')">
            <div class="col-sm-8 col-m-8">
              1. Do you have fever (37.5°C or higher), cough, runny nose, anosmia (i.e. loss of sense of smell), sore throat or shortness of breath?
            </div>
            <div class="col-sm-2 col-m-2">
              <div class="custom-control custom-radio custom-control-inline">
                <input class="custom-control-input" type="radio" value="true" id="FirstDeclaration{{ i }}Yes" formControlName="declaration1" />
                <label class="custom-control-label" for="FirstDeclaration{{ i }}Yes">Yes</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input class="custom-control-input" type="radio" value="false" id="FirstDeclaration{{ i }}No" formControlName="declaration1" />
                <label class="custom-control-label" for="FirstDeclaration{{ i }}No">No</label>
              </div>
            </div>
          </div>
          <hr />
          <div class="row" [class.has-no-selection]="isInvalid(form, 'declaration2')">
            <div class="col-sm-8 col-m-8">
              2. Are you diagnosed or suspected to have COVID-19 infection in the last 21 days?
            </div>
            <div class="col-sm-2 col-m-2">
              <div class="custom-control custom-radio custom-control-inline">
                <input class="custom-control-input" type="radio" value="true" id="SecondDeclaration{{ i }}Yes" formControlName="declaration2" />
                <label class="custom-control-label" for="SecondDeclaration{{ i }}Yes">Yes</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input class="custom-control-input" type="radio" value="false" id="SecondDeclaration{{ i }}No" formControlName="declaration2"/>
                <label class="custom-control-label" for="SecondDeclaration{{ i }}No">No</label>
              </div>
            </div>
          </div>
          <hr />
          <div class="row" [class.has-no-selection]="isInvalid(form, 'declaration3')">
            <div class="col-sm-8 col-m-8">
              3. Did you have close contact with any case of COVID-19 in the last 14 days?
            </div>
            <div class="col-sm-2 col-m-2">
              <div class="custom-control custom-radio custom-control-inline">
                <input class="custom-control-input" type="radio" value="true" id="ThirdDeclaration{{ i }}Yes" formControlName="declaration3"/>
                <label class="custom-control-label" for="ThirdDeclaration{{ i }}Yes">Yes</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input class="custom-control-input" type="radio" value="false" id="ThirdDeclaration{{ i }}No" formControlName="declaration3" />
                <label class="custom-control-label" for="ThirdDeclaration{{ i }}No">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </form>
  </div>
</div>
