import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { map, switchMap, tap } from 'rxjs';
import { AccountService } from '../services/account.service';
import { Flight as AppStateFlight, FlightQueue } from '../services/application-state.service';
import { FlightService } from '../services/flight.service';
import { QueueService } from '../services/queue.service';
import { ResourceService } from '../services/resource.service';
import { PageLoaderService } from '../services/page-loader.service';
import { RestrictedRoute, SettingService } from '../services/setting.service';

interface Flight {
  number: string;
  departureStation: string;
  arrivalStation: string;
  departureDate: Date;
  openForCheckIn: string;
  staffInEconomyClassQueue: number;
  staffInBusinessClassQueue: number;
  staffQueues: StaffQueue[];
  isDirectFlight?: boolean;
}

export interface StaffQueue {
  pnr: string;
  number: string;
  lastName: string;
  firstName: string;
  airline: string;
  registration: Date;
  cabinClass: string
}

interface StationsItem {
  displayName: string;
  code: string;
  codeAndTimeZone: string;
}

@Component({
  selector: 'app-admin-home-widget',
  templateUrl: './admin-home-widget.component.html',
  styleUrls: ['./admin-home-widget.component.scss']
})
export class AdminHomeWidgetComponent {
  flights: Flight[] = [];
  flightSelected: string = '';
  queues: StaffQueue[] = [];
  stationsItem: StationsItem[] = [];
  roleCodes: string[] = [];
  rolesAdminFromSetting: string[] = [];
  SelectedStation: string;
  restrictedRoutesList: RestrictedRoute[] = [];
  SelectedStationName: string;

  constructor(
    private resourceService: ResourceService,
    private settingService: SettingService,
    private accountService: AccountService,
    private flightSerive: FlightService,
    private pageLoaderService: PageLoaderService,
    private queueService: QueueService) {
      this.pageLoaderService.showPageLoader();
      this.resourceService.getStations$().subscribe((stations) => {
        let stationslist: StationsItem[] = [{
          displayName: 'All',
          code: '',
          codeAndTimeZone: ''
        }];
        stations.forEach(station => {
          stationslist.push({
              displayName: `${station.name} (${station.code})`,
              code : station.code,
              codeAndTimeZone : `${station.code}|${station.timeZoneOffSet}`
            })
        });
        this.stationsItem = stationslist;
        let departureStation = this.stationsItem.find(x => x.code === 'SIN')?.codeAndTimeZone ?? "";

        this.accountService.getAccountInfo$().pipe(
          tap(info => this.roleCodes = info!.roleCodes),
          map(info => {

            let location = this.stationsItem.find(x => x.code === info!.primaryLocation);
            let primaryLocation = location?.codeAndTimeZone ?? "";
            this.SelectedStationName = location?.displayName?? "";
            this.SelectedStation = !this.isAdmin() ? primaryLocation : departureStation;
            return this.SelectedStation;
          }),
          map(locationCode => this.loadFlights(locationCode))
        ).subscribe();

        this.settingService.retrieve$().subscribe((data) => {
          this.rolesAdminFromSetting = data.adminPortalRoles['ADMIN'] ?? [];
          this.restrictedRoutesList = data.restrictedRoutesList ?? [];
        });
      });
  }

  ngOnInit(): void {
    this.pageLoaderService.showPageLoader();
  }

  loadFlights(locationCode: string) {
      this.flightSerive.retrieveDepartingFlights$(locationCode).pipe(
        tap(flights => {
          const filterFlight = flights.filter(f => this.restrictedRoutesList.findIndex(i => i.departureStationCode ===  f.departureStation
            && i.arrivalStationCodeList.includes(f.arrivalStation)) < 0 );
          this.pushFlights(filterFlight);
        }),
        switchMap(flights => this.queueService.retrieveQueuesInFlights$(this.createFlightNumberForSearch(flights))),
        tap(queues => this.pushQueues(queues))
      ).subscribe(() => this.pageLoaderService.hidePageLoader());

  }

  createGroups(flights: AppStateFlight[], groupByNumber: number) {
    groupByNumber = groupByNumber > flights.length ? flights.length : groupByNumber;
    const perGroup = Math.ceil(flights.length / groupByNumber);
    return new Array(groupByNumber)
      .fill('')
      .map((_, i) => flights.slice(i * perGroup, (i + 1) * perGroup));
  }

  updateFlights() {
    this.pageLoaderService.showPageLoader();
    this.flights = [];
    this.queues = [];
    this.loadFlights(this.SelectedStation);
  }

  createFlightNumberForSearch(flights: any[]) : string[] {
    return flights.map(f => f.flightNumber.concat(f.departureStation,f.arrivalStation));
  }

  pushQueues(queues: FlightQueue[]){

    this.flights = this.flights.filter(x=>x.isDirectFlight);

    this.flights.forEach(f => {
      const flightDesignator = `${f.number}${f.departureStation}${f.arrivalStation} ${formatDate(f.departureDate, 'yyyyMMdd', 'en-US')}`;
      const flightNumber = f.number;
      const departureStation = f.departureStation;
      const departureDate =formatDate(f.departureDate, 'yyyyMMdd', 'en-US');
      const departureTime = formatDate(f.departureDate, 'HH:mm', 'en-US');

      let newFilteredQueues = queues.filter(q=>
        q.flightDesignator.includes(`${flightNumber}${departureStation}`)
        && q.flightDesignator.includes(`${departureDate}`)
        && q.flightTimes.substring(0, 5) === departureTime
      );
      // let newFilteredQueues = queues.filter(q=>
      //   q.flightDesignator === flightDesignator
      // );

      f.staffInBusinessClassQueue = newFilteredQueues.filter(q => q.cabinClass === 'W' ).length;
      f.staffInEconomyClassQueue = newFilteredQueues.filter(q => q.cabinClass === 'Y').length;

      const filteredQueues = newFilteredQueues;
      if(filteredQueues.length > 0) {
        const staffQueues = filteredQueues.map(q => ({
          pnr: q.referenceCode,
          number: q.queueNumber,
          lastName: q.lastName,
          firstName: q.firstName,
          airline: q.queueNumber?.slice(0, 2),
          registration: q.createdDateTime,
          cabinClass: q.cabinClass
        }) as StaffQueue);
        const trQueues = staffQueues.filter(x => x.airline === 'TR').sort((a, b) => a.number.slice(4) > b.number.slice(4) ? 1 : -1);
        const sqQueues = staffQueues.filter(x => x.airline === 'SQ').sort((a, b) => a.number.slice(4) > b.number.slice(4) ? 1 : -1);
        const oaQueues = staffQueues.filter(x => x.airline === 'OA').sort((a, b) => a.number.slice(4) > b.number.slice(4) ? 1 : -1);
        const otherQueues = staffQueues.filter(x => !['OA', 'TR', 'SQ'].includes(x.airline)).sort((a, b) => a.number.slice(4) > b.number.slice(4) ? 1 : -1);
        f.staffQueues = trQueues.concat(sqQueues.concat(oaQueues.concat(otherQueues)));
      }
    });

    this.flights = this.flights
      .filter(x => x.staffQueues.length > 0);
  }

  pushFlights(flights: any[]){
    flights.forEach(f => this.flights.push({
      departureDate: f.departureDate,
      departureStation: f.departureStation,
      arrivalStation: f.arrivalStation,
      number: f.flightNumber,
      openForCheckIn: f.openForCheckIn,
      staffInBusinessClassQueue: 0,
      staffInEconomyClassQueue: 0,
      staffQueues: [],
      isDirectFlight: f.isDirectFlight
    }));
  }

  isAdmin() : boolean {
    const flagIsAdmin = this.rolesAdminFromSetting.some(item => this.roleCodes.includes(item));
    return flagIsAdmin;
  }

  showFlightQueues(number: string) {
    this.flightSelected = number;
    this.flights.forEach(flight => {
      if(flight.number === number) {
        this.queues = flight.staffQueues;
      }
    })
  }
}
