import { Injectable } from '@angular/core';
import { cloneDeep, forEach } from 'lodash';
import { map, Observable, of, tap } from 'rxjs';
import { QueueAssignmentItem } from '../queue-assignment-table/queue-assignment-table.component';
import { SeatAvailabilityItem } from '../seat-availability-table/seat-availability-table.component';
import { SeatThreshold, Settings, SettingService, RestrictedRoute } from '../services/setting.service';

export interface ForceQueue {
  tr: boolean,
  sq: boolean,
  oa: boolean,
  forceQueueStation: string[]
}

export interface AdminSettings {
  adminRole: string[];
  adminRoleGHA: string[];
  fareClasses: string[];
  allowStandbyCheckIn: boolean;
  allowAutomaticQueue: boolean;
  checkInOpeningTimeDefault: number;
  checkInOpeningTimeSingapore: number;
  checkInCloseTimeBeforeDeparture: number | null;
  checkInDenyTimeBeforeDeparture: number | null;
  restrictedRoutesList: string[];
  specialSSR: string[];
  hideStationCodeDisplayed: string[];
  jumpSeatAvailability: SeatAvailabilityItem[];
  seatThresholdLimit: SeatAvailabilityItem[];
  queueAssignmentMap: QueueAssignmentItem[];
  forceQueue: ForceQueue;
  allowStandbyStation: string[];
  enablePaxlink: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AdminSettingHandlerService {
  adminSettings: AdminSettings = {} as AdminSettings;
  settings: Settings = {} as Settings;
  settingsExpiry: number = 0;

  constructor(
    private settingService: SettingService
  ) {
    this.adminSettings.adminRole = [];
    this.adminSettings.adminRoleGHA = [];
    this.adminSettings.allowStandbyCheckIn = false;
    this.adminSettings.allowAutomaticQueue = false;
    this.adminSettings.fareClasses = [];
    this.adminSettings.checkInOpeningTimeDefault = 0;
    this.adminSettings.checkInOpeningTimeSingapore = 0;
    this.adminSettings.checkInCloseTimeBeforeDeparture = 0;
    this.adminSettings.checkInDenyTimeBeforeDeparture = 0;
    this.adminSettings.specialSSR = [];
    this.adminSettings.hideStationCodeDisplayed = [];
    this.adminSettings.restrictedRoutesList = [];
    this.adminSettings.jumpSeatAvailability = [];
    this.adminSettings.seatThresholdLimit = [];
    this.adminSettings.queueAssignmentMap = [];
    this.adminSettings.forceQueue = {
      tr: false,
      sq: false,
      oa: false,
      forceQueueStation: []
    };
    this.adminSettings.allowStandbyStation = [],
    this.settingsExpiry = 0;
    this.adminSettings.enablePaxlink = false;
  }

  retrieve$(): Observable<AdminSettings> {
    const now = new Date()
    const ttl = 300000;
    if (this.settingsExpiry === 0 || this.settingsExpiry < now.getTime()) {
      this.settingsExpiry = now.getTime() + ttl;
      return this.settingService.retrieve$().pipe(
        tap(settings => this.settings = settings),
        tap(settings => this.mapSettingsToAdminSettings(settings)),
        map(() => this.adminSettings)
      );
    }

    return of(this.adminSettings);
  }

  private mapSettingsToAdminSettings(setting: Settings) {
    const settingClone = cloneDeep(setting);
    this.adminSettings.adminRole = settingClone.adminPortalRoles['ADMIN'] ?? [];
    this.adminSettings.adminRoleGHA = settingClone.adminPortalRoles['GHA'] ?? [];
    this.adminSettings.allowStandbyCheckIn = settingClone.allowStandByCheckIn ?? false;
    this.adminSettings.allowAutomaticQueue = settingClone.allowAutomaticQueue ?? false;
    this.adminSettings.fareClasses = settingClone.fareClass ?? [];
    this.adminSettings.checkInOpeningTimeDefault = settingClone.checkInOpen.defaultOpenTimeInMinutes ?? 0;
    this.adminSettings.checkInOpeningTimeSingapore = settingClone.checkInOpen.checkInStationsList.find(s => s.departureStation === 'SIN')?.openTimeInMinutes ?? 0;
    this.adminSettings.checkInCloseTimeBeforeDeparture = settingClone.checkInCloseTimeBeforeDeparture;
    this.adminSettings.checkInDenyTimeBeforeDeparture = settingClone.checkInDenyTimeBeforeDeparture;
    this.adminSettings.specialSSR = settingClone.specialSSR ?? [];
    this.adminSettings.hideStationCodeDisplayed = settingClone.hideStationCodeDisplayed ?? [];
    this.adminSettings.allowStandbyStation = settingClone.allowStandbyStation ?? [];
    this.adminSettings.enablePaxlink = settingClone.enablePaxlink ?? false;

    if (settingClone.forceQueue)
      this.adminSettings.forceQueue = settingClone.forceQueue;


    if (settingClone != undefined && settingClone != null) {
      this.adminSettings.restrictedRoutesList = [];
      settingClone.restrictedRoutesList.forEach(r => {
        r.arrivalStationCodeList
          .forEach(arrivalStation =>
            this.adminSettings.restrictedRoutesList.push(`${r.departureStationCode}-${arrivalStation}`));
      });

      this.adminSettings.jumpSeatAvailability = [];
      for (let key in settingClone.jumpSeatAvailabilityList) {
        this.adminSettings.jumpSeatAvailability.push({
          aircraftType: key,
          class: 'Economy',
          available: settingClone.jumpSeatAvailabilityList[key]
        } as SeatAvailabilityItem);
      }

      this.adminSettings.seatThresholdLimit = [];
      for (let key in settingClone.seatThresholdList) {
        const item = settingClone.seatThresholdList[key];
        this.adminSettings.seatThresholdLimit.push({
          aircraftType: key,
          class: 'Economy',
          available: item.Economy
        } as SeatAvailabilityItem);

        this.adminSettings.seatThresholdLimit.push({
          aircraftType: key,
          class: 'ScootPlus',
          available: item.ScootPlus
        } as SeatAvailabilityItem);
      }

      this.adminSettings.queueAssignmentMap = [];
      for (let key in settingClone.queueAssignmentMap) {
        this.adminSettings.queueAssignmentMap.push({
          priorityCode: key,
          queueCode: settingClone.queueAssignmentMap[key]
        } as QueueAssignmentItem);
      }
    }

  }

  update$(adminSettings: AdminSettings): Observable<boolean> {
    const updatedSettings = this.mapAdminSettingsToSettings(adminSettings);
    this.settingsExpiry = 0;
    return this.settingService.update$(updatedSettings);
  }

  private mapAdminSettingsToSettings(adminSettings: AdminSettings): Settings {
    let clonesSettings = cloneDeep(this.settings);
    clonesSettings.adminPortalRoles['ADMIN'] = adminSettings.adminRole;
    clonesSettings.adminPortalRoles['GHA'] = adminSettings.adminRoleGHA;

    clonesSettings.allowStandByCheckIn = adminSettings.allowStandbyCheckIn;
    clonesSettings.allowAutomaticQueue = adminSettings.allowAutomaticQueue;

    clonesSettings.fareClass = adminSettings.fareClasses;

    clonesSettings.checkInOpen.checkInStationsList.find(s => s.departureStation === 'SIN')!.openTimeInMinutes = adminSettings.checkInOpeningTimeSingapore ?? 0;
    clonesSettings.checkInOpen.defaultOpenTimeInMinutes = adminSettings.checkInOpeningTimeDefault ?? 0;
    clonesSettings.checkInCloseTimeBeforeDeparture = adminSettings.checkInCloseTimeBeforeDeparture ?? 0;
    clonesSettings.checkInDenyTimeBeforeDeparture = adminSettings.checkInDenyTimeBeforeDeparture ?? 0;

    clonesSettings.specialSSR = adminSettings.specialSSR;

    clonesSettings.hideStationCodeDisplayed = adminSettings.hideStationCodeDisplayed;

    clonesSettings.allowStandbyStation = adminSettings.allowStandbyStation;

    clonesSettings.forceQueue = adminSettings.forceQueue;
    
    clonesSettings.enablePaxlink = adminSettings.enablePaxlink;

    let settingRouteList: RestrictedRoute[] = [];
    adminSettings.restrictedRoutesList.forEach(async (r) => {
      const departureStationCode = r.split('-')[0];
      const arrivalStationCode = r.split('-')[1];

      let route = settingRouteList.find(r => r.departureStationCode === departureStationCode);

      if (route) {
        if (!route.arrivalStationCodeList.includes(arrivalStationCode)) {
          route.arrivalStationCodeList.push(arrivalStationCode);
        }
      }
      else {
        let arrivalStationCodeList: string[] = [];
        let newRoute = { departureStationCode: departureStationCode, arrivalStationCodeList: arrivalStationCodeList };
        newRoute.arrivalStationCodeList.push(arrivalStationCode);
        settingRouteList.push(newRoute);
      }
    });

    clonesSettings.restrictedRoutesList = settingRouteList;

    const jumpSeatAvailabilityDictionary: { [key: string]: number } = {};
    adminSettings.jumpSeatAvailability?.forEach(async (j) => {
      if (j.available != undefined) {
        jumpSeatAvailabilityDictionary[j.aircraftType] = j.available;
      }
    });
    clonesSettings.jumpSeatAvailabilityList = jumpSeatAvailabilityDictionary;

    const seatThresholdLimitDictionary: { [key: string]: SeatThreshold } = {};
    adminSettings.seatThresholdLimit?.forEach(async (s) => {
      let seatThreshold = seatThresholdLimitDictionary[s.aircraftType] ?? { Economy: 0, ScootPlus: 0 };

      if (s.class === 'Economy') {
        seatThreshold.Economy = s.available
      }

      if (s.class === 'ScootPlus') {
        seatThreshold.ScootPlus = s.available
      }
      if (seatThreshold != undefined) {
        seatThresholdLimitDictionary[s.aircraftType] = seatThreshold;
      }
    });
    clonesSettings.seatThresholdList = seatThresholdLimitDictionary;

    const queueAssignmentMapDictionary: { [key: string]: string } = {};
    adminSettings.queueAssignmentMap.forEach(async (q) => {
      if (q.queueCode != undefined) {
        queueAssignmentMapDictionary[q.priorityCode] = q.queueCode;
      }
    });
    clonesSettings.queueAssignmentMap = queueAssignmentMapDictionary;
    clonesSettings.forceQueue = adminSettings.forceQueue;
    return clonesSettings;
  }
}
