import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { ApplicationStateService } from './application-state.service';

export interface Settings {
  adminPortalRoles: { [key: string]: string[] },
  allowStandByCheckIn: boolean,
  allowAutomaticQueue: boolean,
  specialSSR: string[],
  fareClass: string[],
  checkInCloseTimeBeforeDeparture: number,
  checkInDenyTimeBeforeDeparture: number,
  seatThresholdList: { [key: string]: SeatThreshold },
  jumpSeatAvailabilityList: { [key: string]: number },
  checkInOpen: CheckInOpen,
  restrictedRoutesList: RestrictedRoute[],
  hideStationCodeDisplayed: string[],  
  queueAssignmentMap: { [key: string]: string },
  forceQueue: ForceQueue,
  allowStandbyStation: string[],
  enablePaxlink: boolean
}

export interface ForceQueue {
  tr: boolean,
  sq: boolean,
  oa: boolean,
  forceQueueStation: string[]
}

export interface SeatThreshold {
  Economy: number,
  ScootPlus: number
}

export interface CheckInOpen {
  defaultOpenTimeInMinutes: number,
  checkInStationsList: CheckInStation[]
}

export interface CheckInStation {
  openTimeInMinutes: number,
  departureStation: string
}

export interface RestrictedRoute {
  departureStationCode: string,
  arrivalStationCodeList: string[]
}

@Injectable({
  providedIn: 'root'
})

export class SettingService {

  constructor(
    private httpClient: HttpClient,
    private applicationStateService: ApplicationStateService
  ) { }

  retrieve$(): Observable<Settings> {
    return this.httpClient.get<Settings>('/resource/setting');
  }

  update$(setting: Settings): Observable<boolean>{
    return this.httpClient.post('/resource/setting', setting)
    .pipe(
      map(() => true),
      catchError(errorResponse => {
        this.applicationStateService.updateApplicationError(errorResponse.error);
        return of(false);
      })
    );
  }
}
